import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Flag1 from "../../assets/images/flags/Flag1.png";
import Flag2 from "../../assets/images/flags/Flag2.png";
import Flag3 from "../../assets/images/flags/Flag3.png";
import Flag4 from "../../assets/images/flags/Flag4.png";
import Flag5 from "../../assets/images/flags/Flag5.png";
import Flag6 from "../../assets/images/flags/Flag6.png";
import Flag7 from "../../assets/images/flags/Flag7.png";
import Flag8 from "../../assets/images/flags/Flag8.png";
import Flag9 from "../../assets/images/flags/Flag9.png";
import Flag10 from "../../assets/images/flags/Flag10.png";
import Flag11 from "../../assets/images/flags/Flag11.png";
import Flag12 from "../../assets/images/flags/Flag12.png";
// import Flag13 from "../../assets/images/flags/Flag13.png";
// import Flag14 from "../../assets/images/flags/Flag14.png";

import i18next, { t } from "i18next";

const MatchInfoBox = ({ sportsData }) => {
  const navigate = useNavigate();
  const [sortedSports, setSortedSports] = useState([]);
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleMatchCardClick = (event) => {
    navigate(`/betby`);
  };

  const sportsIdsOrder = {
    4: 1,
    2: 2,
    1: 3,
  };
  useEffect(() => {
    if (sportsData) {
      const clonedValue = Array.isArray(sportsData) ? [...sportsData] : [];
      const sortedSportsData =
        Array.isArray(clonedValue) &&
        clonedValue.sort(
          (a, b) => sportsIdsOrder[a.SportId] - sportsIdsOrder[b.SportId]
        );
      setSortedSports(sortedSportsData);
    }
  }, [sportsData]);

  return (
    <div className="match_info_slider mt-3 mb-3 d-none">
      <Slider {...settings}>
        {/* {sortedSports.length &&
          sortedSports.map((item) => {
            return item.values.map((v, i) => {
              const d = moment(v?.matchdate);
              let matchNames = v?.matchName.trim().toLowerCase().includes("vs")
                ? v?.matchName.split(" vs ")
                : v?.matchName.split(" v ");
              return (
                <div o onClick={() => handleMatchCardClick()}nClick={() => handleMatchCardClick(v)}>
                  <div className="info_box">
                    <div className="match">
                      <div class="tournament ">{v?.series_name}</div>
                      <div class="tournament series-name">
                        {item?.sportname}
                      </div>
                    </div>

                    <div className="participants">
                      <div class="item">
                        <img class="flag" src={Flag1} alt="Team Flag" />
                        <div class="name">{matchNames?.[0]}</div>
                      </div>
                      <div class="time">
                        <span>
                          {moment(v?.matchdate).format("dddd DD MMM")}
                        </span>
                        <span className="match-time">
                          {" "}
                          {moment(v?.matchdate).format(" hh:mm A")}
                        </span>
                      </div>
                      <div class="item">
                        <img class="flag" src={Flag2} alt="Team Flag" />
                        <div class="name">{matchNames?.[1]}</div>
                      </div>
                    </div>

                    <div className="market">
                      <div class="outcome">
                        <div class="name">1</div>
                        <div class="odds">2.00</div>
                      </div>
                      <div class="outcome">
                        <div class="name">X</div>
                        <div class="odds">3.20</div>
                      </div>
                      <div class="outcome">
                        <div class="name">2</div>
                        <div class="odds">3.70</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            });
          })} */}

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag3} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag4} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag5} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag6} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag7} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag8} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag9} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag10} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag11} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag12} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => handleMatchCardClick()}>
          <div className="info_box">
            <div className="match">
              <div class="tournament">{t("uefa_europa_league")}</div>
              <div class="time">17.03 01:30</div>
            </div>

            <div className="participants">
              <div class="item">
                <img class="flag" src={Flag1} alt="Team Flag" />
                <div class="name">{t("real_sociedad")}</div>
              </div>
              <div class="item">
                <img class="flag" src={Flag2} alt="Team Flag" />
                <div class="name">{t("as_roma")}</div>
              </div>
            </div>

            <div className="market">
              <div class="outcome">
                <div class="name">1</div>
                <div class="odds">2.00</div>
              </div>
              <div class="outcome">
                <div class="name">X</div>
                <div class="odds">3.20</div>
              </div>
              <div class="outcome">
                <div class="name">2</div>
                <div class="odds">3.70</div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default MatchInfoBox;
