import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import BackIcon from "../../../../assets/svg/BackIcon";
import SignUpPageBanner from "../../../../assets/images/promotion/signupbanner.jpg";
import SignupPromotion from "../../../../assets/images/promotion/signuppromoImg.png";

import { t } from "i18next";

const SigupPromo = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main">
        <div className="container">
          <div className="PageSection promoContent">
            <div className="PageRightBar" style={{ background: "#fff" }}>
              <div className="RightBarHead d-flex justify-content-between">
                <h2>{t("sign_up_bonus")}</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  {t("back")} <BackIcon />
                </div>
              </div>
              <div className="PromoBannerPage mb-5">
                <img src={SignUpPageBanner} alt="signup promotion banner" />
              </div>

              <div className="promosection">
                <div className="left-promotion">
                  <h3>{t("sign_up_bonus")}</h3>
                  <p>{t("signup_bonus_pera")}</p>
                  <p>
                    <span>{t("please_note")} </span>
                    {t("bonus_note1")}
                  </p>
                </div>
                <div className="right-promo">
                  <img src={SignupPromotion} alt="fist-promo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default SigupPromo;
