export const WordCasinoGames = {
  baccarat: {
    href: "/casino/worldcasino/baccarat",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  },

  // Avaitor: {
  //   href: "/casino/worldcasino/Avaitor",
  //   code: "ATR",
  //   casino: "wco",
  //   provider: "PG",
  //   homeUrl: "",
  //   cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  // },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      pilnko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },

    ChineseTreasures: {
      href: "/casino/ezugi/ChineseTreasures",
      code: "1100006",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuck: {
      href: "/casino/ezugi/DragonLuck",
      code: "1100001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFire: {
      href: "/casino/ezugi/DragonFire",
      code: "1100008",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckDeluxe: {
      href: "/casino/ezugi/DragonLuckDeluxe",
      code: "1100011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckReel: {
      href: "/casino/ezugi/DragonLuckReel",
      code: "1100002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    EpicJourney: {
      href: "/casino/ezugi/EpicJourney",
      code: "1100012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GodWealth: {
      href: "/casino/ezugi/GodWealth",
      code: "1100005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyFortune: {
      href: "/casino/ezugi/LuckyFortune",
      code: "1100010",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MagicGate: {
      href: "/casino/ezugi/MagicGate",
      code: "1100009",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTemple: {
      href: "/casino/ezugi/GoldenTemple",
      code: "1100013",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PhoenixPower: {
      href: "/casino/ezugi/PhoenixPower",
      code: "1100014",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiratesPlenty: {
      href: "/casino/ezugi/PiratesPlenty",
      code: "1100015",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenOffer: {
      href: "/casino/ezugi/GoldenOffer",
      code: "1100016",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RAsLegend: {
      href: "/casino/ezugi/RAsLegend",
      code: "1100017",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzoMegawaya: {
      href: "/casino/ezugi/GonzoMegawaya",
      code: "1100018                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJAcket: {
      href: "/casino/ezugi/RainbowJAcket",
      code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaPyramid: {
      href: "/casino/ezugi/MegaPyramid",
      code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Ozzy_Osbourne: {
      href: "/casino/ezugi/Ozzy_Osbourne",
      code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jumanji: {
      href: "/casino/ezugi/Jumanji",
      code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MotorheadVideoSlot: {
      href: "/casino/ezugi/MotorheadVideoSlot",
      code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Starburst: {
      href: "/casino/ezugi/Starburst",
      code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WarofGod: {
      href: "/casino/ezugi/WarofGod",
      code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Christmas: {
      href: "/casino/ezugi/Christmas",
      code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClusterPays: {
      href: "/casino/ezugi/ClusterPays",
      code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AncientsBlessing: {
      href: "/casino/ezugi/AncientsBlessing",
      code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AurumCodex: {
      href: "/casino/ezugi/AurumCodex",
      code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GunsNRoses: {
      href: "/casino/ezugi/GunsNRoses",
      code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BorisandBoo: {
      href: "/casino/ezugi/BorisandBoo",
      code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWest: {
      href: "/casino/ezugi/WildWest",
      code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SteamTower: {
      href: "/casino/ezugi/SteamTower",
      code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ZeusLightning: {
      href: "/casino/ezugi/ZeusLightning",
      code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WingsofRa: {
      href: "/casino/ezugi/WingsofRa",
      code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Trillionaire: {
      href: "/casino/ezugi/Trillionaire",
      code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TreasureMine: {
      href: "/casino/ezugi/TreasureMine",
      code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorVengeance: {
      href: "/casino/ezugi/ThorVengeance",
      code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalStreak: {
      href: "/casino/ezugi/RegalStreak",
      code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalBeasts: {
      href: "/casino/ezugi/RegalBeasts",
      code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKeeper: {
      href: "/casino/ezugi/ReelKeeper",
      code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJackpotsPowerLines: {
      href: "/casino/ezugi/RainbowJackpotsPowerLines",
      code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRichesMegaways: {
      href: "/casino/ezugi/PiggyRichesMegaways",
      code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBells: {
      href: "/casino/ezugi/JingleBells",
      code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackinaPot: {
      href: "/casino/ezugi/JackinaPot",
      code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStar: {
      href: "/casino/ezugi/FiveStar",
      code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BountyRaid: {
      href: "/casino/ezugi/BountyRaid",
      code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReels2: {
      href: "/casino/ezugi/MysteryReels2",
      code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiamondBlitz: {
      href: "/casino/ezugi/DiamondBlitz",
      code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFireMegaways: {
      href: "/casino/ezugi/DragonFireMegaways",
      code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Rome: {
      href: "/casino/ezugi/Rome",
      code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelRush: {
      href: "/casino/ezugi/ReelRush",
      code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers: {
      href: "/casino/ezugi/BloodSuckers",
      code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Pyramid: {
      href: "/casino/ezugi/Pyramid",
      code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx: {
      href: "/casino/ezugi/ButterflyStaxx",
      code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRiches: {
      href: "/casino/ezugi/PiggyRiches",
      code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DarkKing: {
      href: "/casino/ezugi/DarkKing",
      code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleSpin: {
      href: "/casino/ezugi/JingleSpin",
      code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dazzle: {
      href: "/casino/ezugi/Dazzle",
      code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer: {
      href: "/casino/ezugi/JackHammer",
      code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive: {
      href: "/casino/ezugi/DeadorAlive",
      code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackandtheBeanstalk: {
      href: "/casino/ezugi/JackandtheBeanstalk",
      code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2: {
      href: "/casino/ezugi/DeadorAlive2",
      code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivineFortuneMegaways: {
      href: "/casino/ezugi/DivineFortuneMegaways",
      code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GordonRamsayHellsKitchen: {
      href: "/casino/ezugi/GordonRamsayHellsKitchen",
      code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Elements: {
      href: "/casino/ezugi/Elements",
      code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzosQuest: {
      href: "/casino/ezugi/GonzosQuest",
      code: "1100092                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FinnandtheSwirlySpin: {
      href: "/casino/ezugi/FinnandtheSwirlySpin",
      code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShop: {
      href: "/casino/ezugi/FruitShop",
      code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers2: {
      href: "/casino/ezugi/BloodSuckers2",
      code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx2: {
      href: "/casino/ezugi/ButterflyStaxx2",
      code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMeMegaways: {
      href: "/casino/ezugi/DazzleMeMegaways",
      code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2FeatureBuy: {
      href: "/casino/ezugi/DeadorAlive2FeatureBuy",
      code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DoubleStacks: {
      href: "/casino/ezugi/DoubleStacks",
      code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FairytaleLegends: {
      href: "/casino/ezugi/FairytaleLegends",
      code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Flowers: {
      href: "/casino/ezugi/Flowers",
      code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopChristmasEdition: {
      href: "/casino/ezugi/FruitShopChristmasEdition",
      code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline: {
      href: "/casino/ezugi/Hotline",
      code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline2: {
      href: "/casino/ezugi/Hotline2",
      code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheInvisibleMan: {
      href: "/casino/ezugi/TheInvisibleMan",
      code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    IrishPotLuck: {
      href: "/casino/ezugi/IrishPotLuck",
      code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer2: {
      href: "/casino/ezugi/JackHammer2",
      code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitSpin: {
      href: "/casino/ezugi/FruitSpin",
      code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JungleSpirit: {
      href: "/casino/ezugi/JungleSpirit",
      code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    KoiPrincess: {
      href: "/casino/ezugi/KoiPrincess",
      code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DriveMultiplierMayhem: {
      href: "/casino/ezugi/DriveMultiplierMayhem",
      code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSeas: {
      href: "/casino/ezugi/RageoftheSeas",
      code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RichesofMidgard: {
      href: "/casino/ezugi/RichesofMidgard",
      code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ScruffyDuck: {
      href: "/casino/ezugi/ScruffyDuck",
      code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretoftheStones: {
      href: "/casino/ezugi/SecretoftheStones",
      code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretsofChristmas: {
      href: "/casino/ezugi/SecretsofChristmas",
      code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SerengetiKings: {
      href: "/casino/ezugi/SerengetiKings",
      code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SpaceWars: {
      href: "/casino/ezugi/SpaceWars",
      code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Victorious: {
      href: "/casino/ezugi/Victorious",
      code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Warlords: {
      href: "/casino/ezugi/Warlords",
      code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWater: {
      href: "/casino/ezugi/WildWater",
      code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWorlds: {
      href: "/casino/ezugi/WildWorlds",
      code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Squad4: {
      href: "/casino/ezugi/Squad4",
      code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Families5: {
      href: "/casino/ezugi/Families5",
      code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AgentRoyale: {
      href: "/casino/ezugi/AgentRoyale",
      code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ArcadeBomb: {
      href: "/casino/ezugi/ArcadeBomb",
      code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Atlantis: {
      href: "/casino/ezugi/Atlantis",
      code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AztecSpins: {
      href: "/casino/ezugi/AztecSpins",
      code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CinderellaBall: {
      href: "/casino/ezugi/CinderellaBall",
      code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CirquedelaFortune: {
      href: "/casino/ezugi/CirquedelaFortune",
      code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClashoftheBeasts: {
      href: "/casino/ezugi/ClashoftheBeasts",
      code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrazyGenie: {
      href: "/casino/ezugi/CrazyGenie",
      code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrystalMirror: {
      href: "/casino/ezugi/CrystalMirror",
      code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DaVinciMystery: {
      href: "/casino/ezugi/DaVinciMystery",
      code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DevilNumber: {
      href: "/casino/ezugi/DevilNumber",
      code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiceDice: {
      href: "/casino/ezugi/DiceDice",
      code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStarsPowerReels: {
      href: "/casino/ezugi/FiveStarsPowerReels",
      code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWild: {
      href: "/casino/ezugi/GemsGoneWild",
      code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWildPowerReels: {
      href: "/casino/ezugi/GemsGoneWildPowerReels",
      code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenLeprechaunMegaways: {
      href: "/casino/ezugi/GoldenLeprechaunMegaways",
      code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    HoardofPoseidon: {
      href: "/casino/ezugi/HoardofPoseidon",
      code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JewelScarabs: {
      href: "/casino/ezugi/JewelScarabs",
      code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBellsPowerReels: {
      href: "/casino/ezugi/JingleBellsPowerReels",
      code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LegendofAthena: {
      href: "/casino/ezugi/LegendofAthena",
      code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyHalloween: {
      href: "/casino/ezugi/LuckyHalloween",
      code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyLittleDevil: {
      href: "/casino/ezugi/LuckyLittleDevil",
      code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyValentine: {
      href: "/casino/ezugi/LuckyValentine",
      code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyWizard: {
      href: "/casino/ezugi/LuckyWizard",
      code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Masquerade: {
      href: "/casino/ezugi/Masquerade",
      code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MayanGods: {
      href: "/casino/ezugi/MayanGods",
      code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaDragon: {
      href: "/casino/ezugi/MegaDragon",
      code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaJade: {
      href: "/casino/ezugi/MegaJade",
      code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaRise: {
      href: "/casino/ezugi/MegaRise",
      code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MultiplierRiches: {
      href: "/casino/ezugi/MultiplierRiches",
      code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReelsMegaways: {
      href: "/casino/ezugi/MysteryReelsMegaways",
      code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    NinjaWays: {
      href: "/casino/ezugi/NinjaWays",
      code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PathofDestiny: {
      href: "/casino/ezugi/PathofDestiny",
      code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PersianFortune: {
      href: "/casino/ezugi/PersianFortune",
      code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelHeist: {
      href: "/casino/ezugi/ReelHeist",
      code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RobinHoodWildForest: {
      href: "/casino/ezugi/RobinHoodWildForest",
      code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SnowWild: {
      href: "/casino/ezugi/SnowWild",
      code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SylvanSpirits: {
      href: "/casino/ezugi/SylvanSpirits",
      code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheGreatestTrainRobbery: {
      href: "/casino/ezugi/TheGreatestTrainRobbery",
      code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheWildHatter: {
      href: "/casino/ezugi/TheWildHatter",
      code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorLightning: {
      href: "/casino/ezugi/ThorLightning",
      code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TikiFruits: {
      href: "/casino/ezugi/TikiFruits",
      code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TotemLightning: {
      href: "/casino/ezugi/TotemLightning",
      code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultCracker: {
      href: "/casino/ezugi/VaultCracker",
      code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultofAnubis: {
      href: "/casino/ezugi/VaultofAnubis",
      code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WellofWishes: {
      href: "/casino/ezugi/WellofWishes",
      code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildCatsMultiline: {
      href: "/casino/ezugi/WildCatsMultiline",
      code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildOClock: {
      href: "/casino/ezugi/WildOClock",
      code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildChest: {
      href: "/casino/ezugi/WildChest",
      code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WinEscalator: {
      href: "/casino/ezugi/WinEscalator",
      code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    YucatanMystery: {
      href: "/casino/ezugi/YucatanMystery",
      code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
};
