import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../containers/HeaderAfterLogin";
import Footer from "../../../containers/Footer";
import { Tab, Tabs, Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import CasinoAfterIcon from "../../../assets/images/chip.png";
import DepositIcon1 from "../../../assets/images/savings.png";
import Exchange from "../../../assets/images/Exchange.png";
import HomeIcon1 from "../../../assets/images/games-icon/footfav1.png";
import Support from "../../../assets/images/games-icon/support.png";

import AllGame from "../../../assets/images/games-icon/All-Game.png";
import PopularGame from "../../../assets/images/games-icon/Popular.png";
import TopGame from "../../../assets/images/games-icon/Top-game.png";
import SpribeGame from "../../../assets/images/games-icon/Spribe.png";
import RouletteGame from "../../../assets/images/games-icon/Roulette.png";
import BlackjackGame from "../../../assets/images/games-icon/Blackjack.png";
import BaccaratGame from "../../../assets/images/games-icon/Baccart.png";

import TopGameIcon from "../../../assets/images/games-icon/tabs-icon/Top-game.png";
import RouletteGameIcon from "../../../assets/images/games-icon/tabs-icon/Roulette.png";
import BlackjackGameIcon from "../../../assets/images/games-icon/tabs-icon/Blackjack.png";
import BaccaratGameIcon from "../../../assets/images/games-icon/tabs-icon/Baccart.png";
import SpribeGameIcon from "../../../assets/images/games-icon/tabs-icon/Spribe.png";
import PopularGameIcon from "../../../assets/images/games-icon/tabs-icon/Popular.png";

import AmericanRoulette from "../../../assets/images/roulette/casino/American-Roulette.jpg";
import DiamondRoulette from "../../../assets/images/roulette/casino/Diamond-Roulette.jpg";
import DoubleballRoulette from "../../../assets/images/roulette/casino/Double-ball-Roulette.jpg";
import InstantRoulette from "../../../assets/images/roulette/casino/Instant-Roulette.jpg";
import ItalianRoulette from "../../../assets/images/roulette/casino/Italian-Roulette.jpg";
import LightningRoulette from "../../../assets/images/roulette/casino/Lightning-Roulette.jpg";
import NamasteRoulette from "../../../assets/images/roulette/casino/Namaste-Roulette.jpg";
import Oracle360Roulette from "../../../assets/images/roulette/casino/Oracle-360-Roulette.jpg";
import PrestigeRoulette from "../../../assets/images/roulette/casino/Prestige-Auto-Roulette.jpg";
import Roulette1 from "../../../assets/images/roulette/casino/Roulette.jpg";
import RussianRoulette from "../../../assets/images/roulette/casino/Russian-Roulette.jpg";
import SalonPriveRoulette from "../../../assets/images/roulette/casino/Salon-Prive-Roulette.jpg";
import SpeedAutoRoulette from "../../../assets/images/roulette/casino/Speed-Auto-Roulette.jpg";
import AutoRoulette from "../../../assets/images/roulette/casino/Speed-Roulette.jpg";

import Craps from "../../../assets/images/blackjack/casino/Craps.jpg";
import FreebetBlackjack from "../../../assets/images/blackjack/casino/Freebet-Blackjack.jpg";
import GoldBlackjack from "../../../assets/images/blackjack/casino/Gold-Blackjack-4.jpg";
import InfiniteBlackjack from "../../../assets/images/blackjack/casino/Infinite-Blackjack.jpg";
import ItalianBlackjack from "../../../assets/images/blackjack/casino/Italian-Blackjack.jpg";
import LightningBlackjack from "../../../assets/images/blackjack/casino/Lightning-Blackjack.jpg";
import SalonPriveBlackjackA from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-A.jpg";
import SalonPriveBlackjackB from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-B.jpg";
import SalonPriveBlackjackC from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-C.jpg";
import SpeedBlackjack from "../../../assets/images/blackjack/casino/Speed-Blackjack-D.jpg";
import TurkishBlackjack from "../../../assets/images/blackjack/casino/Turkish-Blackjack.jpg";
import UnlimitedBlackjack from "../../../assets/images/blackjack/casino/Unlimited-Blackjack.jpg";
import VipBlackjack2 from "../../../assets/images/blackjack/casino/VIP-Blackjack-2.jpg";
import VipDiamondBlackjack from "../../../assets/images/blackjack/casino/VIP-Diamond-Blackjack.jpg";

import CasinoMarinaBaccarat1 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-1.jpg";
import CasinoMarinaBaccarat2 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-2.jpg";
import CasinoMarinaBaccarat3 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-3.jpg";
import CasinoMarinaBaccarat4 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-4.jpg";
import NoCommBaccarat from "../../../assets/images/baccarat/casino/No-Commission-Baccarat.jpg";
import NoCommSpeedBaccaratA from "../../../assets/images/baccarat/casino/No-Commission-Speed-Baccarat-A.jpg";
import NoCommSpeedBaccaratB from "../../../assets/images/baccarat/casino/No-Commission-Speed-Baccarat-B.jpg";
import RNGBaccarat from "../../../assets/images/baccarat/casino/RNG-Baccarat.jpg";
import RNGBaccarat2020 from "../../../assets/images/baccarat/casino/RNG-Baccarat-2020.jpg";
import SalonBaccaratC from "../../../assets/images/baccarat/casino/Speed-Baccarat-C.jpg";
import SpeedBaccarartB from "../../../assets/images/baccarat/casino/Speed-Baccarat-B.jpg";
import SpeedBaccarartC from "../../../assets/images/baccarat/casino/Speed-Baccarat-C.jpg";
import SpeedBaccarartE from "../../../assets/images/baccarat/casino/Speed-Baccarat-E.jpg";
import GoldenWealthBaccarat from "../../../assets/images/baccarat/casino/Golden-Wealth-Baccarat.jpg";
import LightningBaccarat from "../../../assets/images/baccarat/casino/Lightning-Baccarat.jpg";
import SpeedBaccaratA from "../../../assets/images/baccarat/casino/Speed-Baccarat-A.jpg";
import SpeedCricketBaccarat from "../../../assets/images/baccarat/casino/Speed-Cricket-Baccarat.jpg";
import VIPFortuneBaccarart from "../../../assets/images/baccarat/casino/VIP-Fortune-Baccarat.jpg";
import FiestBaccarat from "../../../assets/images/baccarat/casino/Fiesta-Baccarat.jpg";
import FortuneBaccarat from "../../../assets/images/baccarat/casino/Fortune-Baccarat.jpg";

import AviatorSpribe from "../../../assets/images/spribe/casino/Aviator.jpg";
import MinesSpribe from "../../../assets/images/spribe/casino/mines.jpg";
import DiceSpribe from "../../../assets/images/spribe/casino/dice.jpg";
import GoalSpribe from "../../../assets/images/spribe/casino/Goal.jpg";
import HiloSpribe from "../../../assets/images/spribe/casino/Hilo.jpg";
import PlinkoSpribe from "../../../assets/images/spribe/casino/Plinko.jpg";
import MiniRouletteSpribe from "../../../assets/images/spribe/casino/Mini-roulette.jpg";

import Cards32 from "../../../assets/images/top-game/casino/32-cards.jpg";
import AndarBahar from "../../../assets/images/top-game/casino/andar-bahar.jpg";
import Baccarat from "../../../assets/images/top-game/casino/baccarat.jpg";
import BetonteenPatti from "../../../assets/images/top-game/casino/bet-on-teen-patti.jpg";
import CasinoHoldem from "../../../assets/images/top-game/casino/casino-holdem.jpg";
import CrazyTime from "../../../assets/images/top-game/casino/crazy-time.jpg";
import CricketWar from "../../../assets/images/top-game/casino/Cricket-war.jpg";
import DragonTiger from "../../../assets/images/top-game/casino/dragon-tiger.jpg";
import DreamCatcher from "../../../assets/images/top-game/casino/dream-catcher.jpg";
import FootballStudio from "../../../assets/images/top-game/casino/football-studio.jpg";
import AutoRoulette1 from "../../../assets/images/top-game/casino/auto-roulette.jpg";

import GonzoHunt from "../../../assets/images/popular/casino/gonzo-treasure-hunt.jpg";
import LightDice from "../../../assets/images/popular/casino/lightning-dice.jpg";
import Lucky7 from "../../../assets/images/popular/casino/lucky-7.jpg";
import Megaball from "../../../assets/images/popular/casino/mega-ball.jpg";
import OnedayTeenPatti from "../../../assets/images/popular/casino/one-day-teen-patti.jpg";
import Sicbo from "../../../assets/images/popular/casino/sic-bo.jpg";
import TeenPatti3Card from "../../../assets/images/popular/casino/teen-patti-3-card.jpg";
import UltimateRoulette from "../../../assets/images/popular/casino/ultimate-roulette.jpg";

import Slots1 from "../../../assets/images/slots/casino/Aviator.jpg";
import Slots2 from "../../../assets/images/slots/casino/Twin-spin.jpg";
import Slots3 from "../../../assets/images/slots/casino/Gonzos-hunt.jpg";
import Slots4 from "../../../assets/images/slots/casino/asgardianstones.jpg";
import Slots5 from "../../../assets/images/slots/casino/secret-of-atlantis.jpg";
import Slots6 from "../../../assets/images/slots/casino/grand-wheel.jpg";
import Slots7 from "../../../assets/images/slots/casino/777_Strike.jpg";
import Slots8 from "../../../assets/images/slots/casino/10001Nights.jpg";
import Slots9 from "../../../assets/images/slots/casino/Cash_Ultimate.jpg";
import Slots10 from "../../../assets/images/slots/casino/Cash_Volt.jpg";
import Slots11 from "../../../assets/images/slots/casino/Dazzle_Me_Megaways.jpg";
import Slots12 from "../../../assets/images/slots/casino/Divine_Fortune_Megaways.jpg";
import Slots13 from "../../../assets/images/slots/casino/Dynamite_Riches.jpg";
import Slots14 from "../../../assets/images/slots/casino/FortuneHouse.jpg";
import Slots15 from "../../../assets/images/slots/casino/Fruit_Shop_Megaways.jpg";
import Slots16 from "../../../assets/images/slots/casino/Golden_Cryptex.jpg";
import Slots17 from "../../../assets/images/slots/casino/Golden_star.jpg";
import Slots18 from "../../../assets/images/slots/casino/Gorilla_Kingdom.jpg";
import Slots19 from "../../../assets/images/slots/casino/Mystery_Reels_Megaways.jpg";
import Slots20 from "../../../assets/images/slots/casino/Rage_Of_The_Seas.jpg";
import Slots22 from "../../../assets/images/slots/casino/Reel_King_Mega.jpg";
import Slots24 from "../../../assets/images/slots/casino/Turn_Your_Fortune.jpg";
import Slots25 from "../../../assets/images/slots/casino/Twin_Spin_Megaways.jpg";
import Slots27 from "../../../assets/images/slots/casino/Willys_Hot_Chillies.jpg";

import PlayBtn from "../../../assets/images/play-btn.png";

import i18next, { t } from "i18next";
import { useDispatch } from "react-redux";

const CasinoAfter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("2");
  const [activePage, setActivePage] = useState("");
  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "All Games",
      icon: <img src={AllGame} />,
    },
    {
      id: 2,
      eventKey: "2",
      name: "Top",
      icon: <img src={TopGame} />,
    },
    {
      id: 3,
      eventKey: "3",
      name: "Popular",
      icon: <img src={PopularGame} />,
    },
    {
      id: 4,
      eventKey: "4",
      name: "Roulette",
      icon: <img src={RouletteGame} />,
    },
    {
      id: 5,
      eventKey: "5",
      name: "Blackjack",
      icon: <img src={BlackjackGame} />,
    },
    {
      id: 6,
      eventKey: "6",
      name: "Baccarat",
      icon: <img src={BaccaratGame} />,
    },
    {
      id: 7,
      eventKey: "7",
      name: "Spribe",
      icon: <img src={SpribeGame} />,
    },
    {
      id: 8,
      eventKey: "8",
      name: "Slots",
      icon: <img src={SpribeGame} />,
    },
  ];

  useEffect(() => {
    if (location?.state?.selectedTab) {
      console.log("location?.state?.selectedTab", location?.state?.selectedTab);
      setActiveKey(location.state.selectedTab);
    }
  }, [location]);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <div className="CasinoSecton">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="2"
          activeKey={activeKey}
        >
          <Nav variant="pills" className="top_nav">
            {tabs.map((item, index) => {
              return (
                <Nav.Item
                  key={index}
                  onClick={() => setActiveKey(item.eventKey)}
                >
                  <Nav.Link eventKey={item.eventKey}>
                    {item.icon} {item.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <div className="games-section">
                <div className="game-heading">
                  <img src={TopGameIcon} />
                  <span>All Games</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/ezugi/AutoRoulette">
                      <img src={AutoRoulette1} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/thirtytwoCards">
                      <img src={Cards32} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/AndarBahar">
                      <img src={AndarBahar} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/SpeedRoulette">
                      <img src={AutoRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Baccarat">
                      <img src={Baccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/BetonteenPatti">
                      <img src={BetonteenPatti} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoHoldem">
                      <img src={CasinoHoldem} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/CrazyTime">
                      <img src={CrazyTime} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="games-section">
                <div className="game-heading">
                  <img src={PopularGameIcon} />
                  <span>{t("popular_games")}</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/GonzoTreasureHunt">
                      <img src={GonzoHunt} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningDice">
                      <img src={LightDice} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningRoulette">
                      <img src={LightningRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/lucky7">
                      <img src={Lucky7} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/Megaball">
                      <img src={Megaball} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/NamasteRoulette">
                      <img src={NamasteRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/casino/ezugi/OneDayTeenPatti">
                    <img src={OnedayTeenPatti} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a href="/casino/ezugi/SicBo">
                      <img src={Sicbo} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="games-section">
                <div className="game-heading">
                  <img src={SpribeGameIcon} />
                  <span>Spribe</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={AviatorSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/dice">
                      <img src={DiceSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/goal">
                      <img src={GoalSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/hilo">
                      <img src={HiloSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/plinko">
                      <img src={PlinkoSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/miniroulette">
                      <img src={MiniRouletteSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/mines">
                      <img src={MinesSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="games-section">
                <div className="game-heading">
                  <img src={RouletteGameIcon} />
                  <span>Roulette</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/AmericanRoulette">
                      <img src={AmericanRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DiamondRoulette">
                      <img src={DiamondRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/DoubleBallRoulette">
                      <img src={DoubleballRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/InstantRoulette">
                      <img src={InstantRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/ItalianRoulette">
                      <img src={ItalianRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningRoulette">
                      <img src={LightningRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/NamasteRoulette">
                      <img src={NamasteRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Oracle360Roulette">
                      <img src={Oracle360Roulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="games-section">
                <div className="game-heading">
                  <img src={BlackjackGameIcon} />
                  <span>Blackjack</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/Craps">
                      <img src={Craps} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/FreebetBlackjack">
                      <img src={FreebetBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/UnlimitedBlackjack">
                      <img src={GoldBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/InfiniteBlackjack">
                      <img src={InfiniteBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/ItalianBlackjack">
                      <img src={ItalianBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningBlackjack">
                      <img src={LightningBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveBlackjackA">
                      <img src={SalonPriveBlackjackA} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveBlackjackB">
                      <img src={SalonPriveBlackjackB} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="games-section">
                <div className="game-heading">
                  <img src={BaccaratGameIcon} />
                  <span>Baccarat</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat1">
                      <img src={CasinoMarinaBaccarat1} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat2">
                      <img src={CasinoMarinaBaccarat2} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat3">
                      <img src={CasinoMarinaBaccarat3} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat4">
                      <img src={CasinoMarinaBaccarat4} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Fiestaaccarat">
                      <img src={FiestBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FortuneBaccarat">
                      <img src={FortuneBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/GoldenWealthBaccarat">
                      <img src={GoldenWealthBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningBaccarat">
                      <img src={LightningBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <div className="games-section">
                <div className="game-heading">
                  <img src={TopGameIcon} />
                  <span>Top Games</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/ezugi/AutoRoulette">
                      <img src={AutoRoulette1} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/thirtytwoCards">
                      <img src={Cards32} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/AndarBahar">
                      <img src={AndarBahar} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/SpeedRoulette">
                      <img src={AutoRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Baccarat">
                      <img src={Baccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/BetonteenPatti">
                      <img src={BetonteenPatti} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoHoldem">
                      <img src={CasinoHoldem} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/CrazyTime">
                      <img src={CrazyTime} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CircketWar">
                      <img src={CricketWar} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DragonTigerr">
                      <img src={DragonTiger} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/DreamCatcher">
                      <img src={DreamCatcher} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/FootballStudio">
                      <img src={FootballStudio} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FortuneBaccarat">
                      <img src={FortuneBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <div className="games-section">
                <div className="game-heading">
                  <img src={PopularGameIcon} />
                  <span>Popular Games</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/GonzoTreasureHunt">
                      <img src={GonzoHunt} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningDice">
                      <img src={LightDice} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningRoulette">
                      <img src={LightningRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/lucky7">
                      <img src={Lucky7} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/Megaball">
                      <img src={Megaball} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/NamasteRoulette">
                      <img src={NamasteRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/casino/ezugi/OneDayTeenPatti">
                    <img src={OnedayTeenPatti} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a href="/casino/ezugi/SicBo">
                      <img src={Sicbo} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SpeedBaccaratA">
                      <img src={SpeedBaccaratA} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/TeenPatti3Card">
                      <img src={TeenPatti3Card} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/UltimateRoulette">
                      <img src={UltimateRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <div className="games-section">
                <div className="game-heading">
                  <img src={RouletteGameIcon} />
                  <span>Roulette</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/AmericanRoulette">
                      <img src={AmericanRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DiamondRoulette">
                      <img src={DiamondRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/DoubleBallRoulette">
                      <img src={DoubleballRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/InstantRoulette">
                      <img src={InstantRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/ItalianRoulette">
                      <img src={ItalianRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningRoulette">
                      <img src={LightningRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/NamasteRoulette">
                      <img src={NamasteRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Oracle360Roulette">
                      <img src={Oracle360Roulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DiamondRoulette">
                      <img src={PrestigeRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/Roulette">
                      <img src={Roulette1} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/RussianRoulett">
                      <img src={RussianRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveRoulette">
                      <img src={SalonPriveRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/SpeedAutoRoulette">
                      <img src={SpeedAutoRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/SpeedRoulette">
                      <img src={AutoRoulette} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <div className="games-section">
                <div className="game-heading">
                  <img src={BlackjackGameIcon} />
                  <span>Blackjack</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/evolution/Craps">
                      <img src={Craps} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/FreebetBlackjack">
                      <img src={FreebetBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/UnlimitedBlackjack">
                      <img src={GoldBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/InfiniteBlackjack">
                      <img src={InfiniteBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/ItalianBlackjack">
                      <img src={ItalianBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningBlackjack">
                      <img src={LightningBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveBlackjackA">
                      <img src={SalonPriveBlackjackA} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveBlackjackB">
                      <img src={SalonPriveBlackjackB} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SalonPriveBlackjackC">
                      <img src={SalonPriveBlackjackC} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SpeedBlackjackD">
                      <img src={SpeedBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/TurkishBlackjack">
                      <img src={TurkishBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/UnlimitedBlackjack">
                      <img src={UnlimitedBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/VIPBlackjack2">
                      <img src={VipBlackjack2} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/VIPDiamondBlackjack">
                      <img src={VipDiamondBlackjack} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="6">
              <div className="games-section">
                <div className="game-heading">
                  <img src={BaccaratGameIcon} />
                  <span>Baccarat</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat1">
                      <img src={CasinoMarinaBaccarat1} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat2">
                      <img src={CasinoMarinaBaccarat2} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat3">
                      <img src={CasinoMarinaBaccarat3} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CasinoBaccarat4">
                      <img src={CasinoMarinaBaccarat4} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Fiestaaccarat">
                      <img src={FiestBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FortuneBaccarat">
                      <img src={FortuneBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/GoldenWealthBaccarat">
                      <img src={GoldenWealthBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/LightningBaccarat">
                      <img src={LightningBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/NoCommissionBaccarat">
                      <img src={NoCommBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/NoCommSpeedBaccaratA">
                      <img src={NoCommSpeedBaccaratA} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/NoCommSpeedBaccaratB">
                      <img src={NoCommSpeedBaccaratB} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a href="">
                    <img src={RNGBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={RNGBaccarat2020} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a href="/casino/evolution/SpeedBaccaratC">
                      <img src={SalonBaccaratC} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SpeedBaccaratA">
                      <img src={SpeedBaccaratA} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution/SpeedBaccaratB">
                      <img src={SpeedBaccarartB} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/casino/evolution/SpeedBaccaratC">
                    <img src={SpeedBaccarartC} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a href="/casino/evolution/SpeedBaccaratE">
                      <img src={SpeedBaccarartE} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FortuneBaccarat">
                      <img src={SpeedCricketBaccarat} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a href="">
                    <img src={VIPFortuneBaccarart} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="7">
              <div className="games-section">
                <div className="game-heading">
                  <img src={SpribeGameIcon} />
                  <span>Spribe</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={AviatorSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/dice">
                      <img src={DiceSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/goal">
                      <img src={GoalSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/hilo">
                      <img src={HiloSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/plinko">
                      <img src={PlinkoSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/miniroulette">
                      <img src={MiniRouletteSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/mines">
                      <img src={MinesSpribe} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="8">
              <div className="games-section">
                <div className="game-heading">
                  <img src={SpribeGameIcon} />
                  <span>Slots</span>
                </div>
                <ul>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={Slots1} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/twinspin">
                      <img src={Slots2} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/gonzoquest">
                      <img src={Slots3} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/asgardianstones">
                      <img src={Slots4} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/secrets">
                      <img src={Slots5} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/grandwheel">
                      <img src={Slots6} alt="Live casino game" />
                      <div className="game_name"></div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Strike777">
                      <img src={Slots7} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Nights1000">
                      <img src={Slots8} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CashUltimate">
                      <img src={Slots9} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/CashVolt">
                      <img src={Slots10} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DazzleMegaways">
                      <img src={Slots11} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DivinieFortuneMegaways">
                      <img src={Slots12} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/Dynamiteriches">
                      <img src={Slots13} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FortuneHouse">
                      <img src={Slots14} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/FruitShopMegaways">
                      <img src={Slots15} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/GoldenCryptex">
                      <img src={Slots16} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/GoldenTsar">
                      <img src={Slots17} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/casino/ezugi/GorillaKingdom">
                      <img src={Slots18} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/MystryReels">
                      <img src={Slots19} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/RageoftheSea">
                      <img src={Slots20} alt="Live casino game" />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/casino/ezugi/ReelKingMegaways">
                      <img src={Slots22} alt="Live casino game" />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/casino/ezugi/TurnFortune">
                      <img src={Slots24} alt="Live casino game" />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/TwinSpinMegaways">
                      <img src={Slots25} alt="Live casino game" />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/casino/ezugi/WillysChills">
                      <img src={Slots27} alt="Live casino game" />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <footer className="footer main">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "betby" ? "active" : ""}
                href="/betby"
              >
                <img src={Exchange} alt="Exchange Icon" />
                {/* <SportsIcon /> */}
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "Casioafter" ? "active" : ""}
                href="/Casioafter"
              >
                {/* <SportsIcon /> */}
                <img src={CasinoAfterIcon} alt="Exchange Icon" />
                <span>Casino</span>
              </a>
            </li>
            <li className="active-foot">
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <img src={HomeIcon1} />
                {/* <HomeIcon /> */}
                {/* <span>Home</span> */}
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                className={activePage === "deposit" ? "active" : ""}
                href="/deposit"
              >
                {/* <GamesIcon /> */}
                {/* <CashierIcon /> */}
                <img src={DepositIcon1} alt="Exchange Icon" />

                <span>Deposit</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "contactus" ? "active" : ""}
                href="/contactus"
              >
                {/* <CashierIcon /> */}
                <img src={Support} />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default CasinoAfter;
