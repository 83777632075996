import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import BackIcon from "../../../../assets/svg/BackIcon";
import weekendPrmoPageBanner from "../../../../assets/images/promotion/weekendpromopagebanner.jpg";
import WeekendPromotion from "../../../../assets/images/promotion/lossbonus.png";

import { t } from "i18next";

const WeekendPromo = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main">
        <div className="container">
          <div className="PageSection promoContent">
            <div className="PageRightBar" style={{ background: "#fff" }}>
              <div className="RightBarHead d-flex justify-content-between">
                <h2>{t("weekend_deposit_bonus")}</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  {t("back")} <BackIcon />
                </div>
              </div>

              <div className="PromoBannerPage mb-5">
                <img
                  src={weekendPrmoPageBanner}
                  alt="signup promotion banner"
                />
              </div>
              <div className="promosection LossBonus">
                <div className="left-promotion">
                  <h3>{t("weekend_exclusive_bonus")}</h3>
                  <p>{t("loss_deposit_bonus_note1")}</p>
                  <div className="right-promo">
                    <img src={WeekendPromotion} alt="fist-promo" />
                  </div>
                  {/* <p>{t("weekend_deposit_bonus_pera1")}</p>
                  <p>{t("weekend_deposit_bonus_pera2")}</p>
                  <p>
                    <span>{t("please_note")}</span>
                    {t("bonus_note1")}
                  </p>
                  <p>
                    <span>{t("please_note")}</span>
                    {t("bonus_note2")}
                  </p>

                  <p>
                    <span>{t("please_note")}</span>
                    {t("weekend_deposit_bonus_note3")}
                  </p>
                  <p>
                    <span>{t("please_note")}</span>
                    {t("bonus_note4")}
                  </p> */}
                  {/* <h5>{t("casino_criteria_turnover")}</h5>
                  <ul>
                    <li>
                      <span style={{ color: "#e20001" }}>
                        {t("dragon_tiger")}
                      </span>{" "}
                      {t("dragon_tiger_text")}
                    </li>
                    <li>
                      <span style={{ color: "#e20001" }}>
                        {t("andar_bahar")}
                      </span>{" "}
                      -{t("andar_bahar_text")}
                    </li>
                    <li>
                      <span style={{ color: "#e20001" }}>{t("lucky7")}</span>
                      {t("lucky7_text")}
                    </li>
                    <li>
                      <span style={{ color: "#e20001" }}>{t("baccarat")}</span>
                      {t("baccarat_text")}
                    </li>
                    <li>
                      <span style={{ color: "#e20001" }}>
                        {t("cricket_war")}
                      </span>
                      {t("cricket_war_text")}
                    </li>
                    <li>
                      {" "}
                      <span style={{ color: "#e20001" }}>{t("sic_bo")}</span>
                      {t("sic_bo_text")}
                    </li>
                    <li>
                      <span style={{ color: "#e20001" }}>
                        {t("speed_roulette")}
                      </span>
                      {t("speed_roulette_text")}
                    </li>
                  </ul>

                  <h5>{t("sports_criteria_turnover")}</h5>
                  <ul>
                    <li>{t("sports_criteria_turnover_pera")}</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default WeekendPromo;
