import React, { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  FormGroup,
  Accordion,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
// import CricketIcon from "../../assets/svg/CricketIcon";
// import FastGameIcon from "../../assets/svg/FastGameIcon";
// import LiveDealerIcon from "../../assets/svg/LiveDealerIcon";
// import GameShowIcon from "../../assets/svg/GameShowIcon";
// import RummyIcon from "../../assets/svg/RummyIcon";
import { casinoTabs, EzugiGames } from "../../lib/data";
import CasinoCard from "./CasinoCard";
import CasinoGames from "./CasinoGames";
import MatchInfoSlider from "./MatchInfoBox";
import MainSlider from "../../containers/MainSlider";

import { useSelector } from "react-redux";

import { sportsSocket } from "../../utils/socketUtil";
import { setSportsData, setOddsData } from "../../redux/sports/actions";
import { useDispatch } from "react-redux";
import { getAppDetails, submitUserDetails } from "../../redux/app/actions";
import i18next, { t } from "i18next";
import esport from "../../assets/images/ESPORTS.png";
import livescasino from "../../assets/images/livescasino.png";
import lotterygames from "../../assets/images/lotterygames.png";
import slotsgames from "../../assets/images/slotsgames.png";
import card from "../../assets/images/card.png";
import ms from "../../assets/images/ms.png";
import home from "../../assets/images/home.png";
import sec from "../../assets/images/90sec.png";
import bottomimages from "../../assets/images/bottomimages.png";

import homee from "../../assets/images/homee.png";

import sportsImg from "../../assets/images/sportsbetting.png";
import AppRightImg from "../../assets/images/appmobile.png";
import dota from "../../assets/images/deposit-icon/dota.png";
import csgo from "../../assets/images/deposit-icon/csgo.png";
import HeroesoftheStorm from "../../assets/images/deposit-icon/HeroesoftheStorm.png";
import leagueoflegends from "../../assets/images/deposit-icon/leagueoflegends.png";
import Overwatch from "../../assets/images/deposit-icon/Overwatch.png";
import starcraft from "../../assets/images/deposit-icon/starcraft.png";
import valorant from "../../assets/images/deposit-icon/valorant.png";

import EvolutionProvider from "../../assets/images/live-casino/Evolution.png";
import EzugiProvider from "../../assets/images/live-casino/Ezugi.png";
import PragmaticProvider from "../../assets/images/live-casino/PragmaticPlay.png";
import VivoProvider from "../../assets/images/live-casino/VivoGaming.png";

import Cards3Icon1 from "../../assets/images/card/3Cards.png";
import Cards32Icon from "../../assets/images/card/32Cards.png";
import TeenPattiIcon from "../../assets/images/card/TeenPatti.png";

import Netent from "../../assets/images/slots/icons/Netent.png";
import OneTouch from "../../assets/images/slots/icons/OneTouch.png";
import Qtech from "../../assets/images/slots/icons/Qtech.png";
import RedTiger from "../../assets/images/slots/icons/RedTiger.png";
import Supernova from "../../assets/images/slots/icons/Supernova.png";
import cricket from "../../assets/images/cricket.png";
import basketball from "../../assets/images/basketball.png";
import icehockey from "../../assets/images/ice-hockey.png";

import football from "../../assets/images/football.png";
import volleyball from "../../assets/images/volleyball.png";
import sportsbet from "../../assets/images/sportsbetting1.png";
import esport3 from "../../assets/images/Esports3.png";
import livecasino from "../../assets/images/Livecasino.png";
import Slots from "../../assets/images/Slots.png";
import cardgames from "../../assets/images/CardGame.png";

import SportsBanner1 from "../../assets/images/banner/bonus-sign.jpg";
import SportsBanner2 from "../../assets/images/banner/refer-signup.jpg";
import SportsBanner3 from "../../assets/images/banner/deposit-bonus.jpg";

import SignBunusImg from "../../assets/images/modal/Mobilelogin-bro.png";

import SportsRugby from "../../assets/images/games-icon/rugby-ball.png";

import SpribeAviator from "../../assets/images/spribe/fantasy/Aviator.jpg";
import SpribeGifBanner from "../../assets/images/banner/spribe.gif";
import SpribeMiniroulette from "../../assets/images/spribe/fantasy/MiniROulette.jpg";
import SpribeDice from "../../assets/images/spribe/fantasy/Dice.jpg";
import SpribeHilo from "../../assets/images/spribe/fantasy/Hilo.jpg";
import SpribeMines from "../../assets/images/spribe/fantasy/Mine.jpg";
import SpribeGoal from "../../assets/images/spribe/fantasy/Goal.jpg";

import DialWin from "../../assets/images/dial4win.png";

import SportsBettingGif from "../../assets/images/sportsbetting.gif";
import SpribeImg from "../../assets/images/games-icon/avia.png";
import SportsBettingImg from "../../assets/images/sports-img.png";
import AviatorImg from "../../assets/images/aviator-img.png";
import TopGames1 from "../../assets/images/top-game/spribe/aviator.jpg";
import PhoneInput from "react-phone-input-2";

import { Form } from "react-bootstrap";

import PlinkoGame from "../../assets/images/games/Plinko.png";
import GoalGame from "../../assets/images/games/gOAL.png";
import DiceGame from "../../assets/images/games/dICE.png";
import HiloGame from "../../assets/images/games/Hilospribe.png";
import MineGame from "../../assets/images/games/Minespribe.png";
import MiniRouletteGame from "../../assets/images/games/mINIROULETTE.png";
import AviatorGame from "../../assets/images/games/Aviator.png";

import FirstPromoImg from "../../assets/images/promotion/firstprmotion.png";
import SecondPromoImg from "../../assets/images/promotion/secondpromotion.png";
import ThirdPromoImg from "../../assets/images/promotion/thirdpromotion.png";
import SignuPromoImg from "../../assets/images/promotion/signuppromotion.png";
import ReferralPromoImg from "../../assets/images/promotion/referralpromotion.png";
import WeekendPromoImg from "../../assets/images/promotion/weekendpromotion.png";

import WelocomeMobile from "../../assets/images/wemobile.png";

import Indiaflag from "../../assets/images/icons/UAE.png";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../utils/toaster";
import { APP_CONST } from "../../config/const";

const LandingPAge = () => {
  const navigate = useNavigate();
  const { banners } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [publicUserDetails, setPublicUserDetails] = useState({
    email: "",
    mobileNumber: "",
  });
  const [isSubmittingUserDeatil, setIsSubmittingUserDeatil] = useState(false);

  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };
  const { appDetails } = useSelector((state) => state.app);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handlePublicUserDetails = async () => {
    setIsSubmittingUserDeatil(true);
    const response = await submitUserDetails(publicUserDetails);
    if (response?.data?.error === false) {
      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Details submitted");
    }
    setIsSubmittingUserDeatil(false);
    // window.open(
    //   appDetails?.WHATS_APP_URL != ""
    //     ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
    //     : "javascript:void(0)"
    // );
  };
  return (
    <>
      <main className="main">
        {/* <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div> */}
        <div className="top_banner_sec">
          <div className="mainBanner">
            <MainSlider banners={banners} />
          </div>
        </div>

        <MatchInfoSlider />

        <div className="MatchIframeBefore d-none">
          <div className="matchContent">
            <div className="MatchConatctHeading">
              <img src={SportsRugby} alt="rugby" />
              <span>Sports</span>
            </div>
            <p>
              Dive in to our wide range of in-house games, live casino and slots
              to experience a thrilling casino adventure.
            </p>

            <div className="matchButton">
              <a href="/sign-in" className="btn btn-primary">
                Sports
              </a>
              <a href="/sign-in" className="btn btn-primary liveBtn">
                Live
              </a>
            </div>
          </div>
        </div>

        <div className="enjoye-sf-gaming">
          <div className="container">
            <h2>{t("enjoy_sf_gaming")}</h2>

            <div className="content-wrapper">
              <div className="content-box">
                <h5>{t("sf_gaming_step1")}</h5>
                <p>{t("sf_gaming_text1")}</p>
              </div>
              <div className="content-box">
                <h5>{t("sf_gaming_step2")}</h5>
                <p>{t("sf_gaming_text2")}</p>
              </div>
              <div className="content-box">
                <h5>{t("sf_gaming_step3")}</h5>
                <p>{t("sf_gaming_text3")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="welcome-offer">
          <div className="container-fluid">
            <Row>
              <Col md="6" xs="12">
                <div className="WelcomeContentSection">
                  <div className="welcomeContent">
                    <h4>{t("welcom_text1")}</h4>
                    <h3>{t("welcom_text2")}</h3>
                    <h4>
                      <span>{t("welcom_text3")} </span>
                      {t("welcom_text4")}
                    </h4>
                  </div>

                  <div className="WelcomeForm">
                    <p>{t("welcom_text5")}</p>
                    <Form>
                      <FormGroup
                        className=" position-relative mb-3"
                        controlId="formBasicPassword"
                      >
                        <div className="phoneinput ">
                          <PhoneInput
                            country={"ae"}
                            inputProps={{
                              name: "phone",
                              required: true,
                            }}
                            inputStyle={{
                              paddingLeft: "120px !important",
                              height: "38px",
                            }}
                            onChange={(phone) => {
                              setPublicUserDetails((p) => ({
                                ...p,
                                mobileNumber: phone,
                              }));
                            }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup
                        className=" position-relative mb-3"
                        controlId="formBasicPassword"
                      >
                        <input
                          type="text"
                          id="phone"
                          name="email"
                          placeholder="Enter your Email"
                          className="phoneinput mailinput"
                          required
                          onChange={(e) => {
                            setPublicUserDetails((p) => ({
                              ...p,
                              email: e.target.value,
                            }));
                          }}
                        />
                      </FormGroup>
                      <div
                        onClick={handlePublicUserDetails}
                        className={
                          isSubmittingUserDeatil ? "Issubmit" : "panel"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {isSubmittingUserDeatil ? "Submitting..." : "Submit"}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="d-md-block d-none">
                  <div className="WelcomeMobileImg">
                    <img src={WelocomeMobile} alt="WelocomeMobile" />
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-md-none d-block">
              <div className="WelcomeMobileImg">
                <img src={WelocomeMobile} alt="WelocomeMobile" />
              </div>
            </div>
          </div>
        </div>

        <div className="top-games">
          <div className="container">
            <h2>{t("top_games")}</h2>
            <div className="GameSection">
              <div className="games">
                <a
                  className="aviator game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={PlinkoGame} alt="aviator" />
                </a>
                <a
                  className="mine game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={MineGame} alt="mine" />
                </a>
                <a
                  className="mini-roulette game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={MiniRouletteGame} alt="mini roulette" />
                </a>
                <a
                  className="goal game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={GoalGame} alt="goal" />
                </a>
                <a
                  className="hilo game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={HiloGame} alt="hilo" />
                </a>
                <a
                  className="dice game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={DiceGame} alt="dice" />
                </a>
              </div>
              <div className="gameRightPart">
                <a
                  className="aviator game"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={AviatorGame} alt="aviator" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="promotionSection">
          <div className="container">
            <h2>{t("promotion")}</h2>

            <div className="promo-section">
              <ul>
                <li>
                  <a href="/signuppromos" className="promo-content">
                    <img src={SignuPromoImg} />
                    <div className="content">
                      <h4>{t("sign_up_bonus")}</h4>
                      <p>{t("sign_up_bonus_p_one")}</p>
                      <a href="/signuppromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/firstpromos" className="promo-content">
                    <img src={FirstPromoImg} />
                    <div className="content">
                      <h4>{t("first_deposit_bonus")}</h4>
                      <p>{t("first_deposit_bonus_p_one")}</p>
                      <a href="/firstpromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/secondpromos" className="promo-content">
                    <img src={SecondPromoImg} />
                    <div className="content">
                      <h4>{t("second_deposit_bonus")}</h4>
                      <p>{t("second_deposit_bonus_p_one")}</p>
                      <a href="/secondpromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/thirdpromos" className="promo-content">
                    <img src={ThirdPromoImg} />
                    <div className="content">
                      <h4>{t("third_deposit_bonus")}</h4>
                      <p>{t("third_deposit_bonus_p_one")}</p>
                      <a href="/thirdpromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/referralpromos" className="promo-content">
                    <img src={ReferralPromoImg} />
                    <div className="content">
                      <h4>{t("referral_bonus")}</h4>
                      <p>{t("referral_bonus_p_one")}</p>
                      <a href="/referralpromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/weekendpromos" className="promo-content">
                    <img src={WeekendPromoImg} />
                    <div className="content">
                      <h4>{t("weekend_exclusive_bonus")}</h4>
                      <p>{t("loss_deposit_bonus_homepage")}</p>
                      <a href="/weekendpromos">{t("see_more")}</a>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="FAQWrapper">
            <div className="promotiion-content">
              {/* <h4>Frequently Asked Questions</h4> */}
              <Accordion>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>{t("faq")}</Accordion.Header>
                  <Accordion.Body>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>{t("faq_header1")}</Accordion.Header>
                        <Accordion.Body>
                          {/* <div className="promotionimg">
                        <img src={DepositImgPromotion} />
                      </div> */}

                          <p>{t("faq_text1")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("faq_header2")}</Accordion.Header>
                        <Accordion.Body>
                          <div className="promotionimg">
                            {/* <img src={DepositImgPromotion} /> */}
                          </div>

                          <p>{t("faq_text2")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>{t("faq_header3")}</Accordion.Header>
                        <Accordion.Body>
                          <div className="promotionimg">
                            {/* <img src={FirstDepositImgPromotion} /> */}
                          </div>

                          <p>{t("faq_text3")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>{t("faq_header4")}</Accordion.Header>
                        <Accordion.Body>
                          <div className="promotionimg">
                            {/* <img src={SecondDepositImgPromotion} /> */}
                          </div>
                          <p>{t("faq_text4")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>{t("faq_header5")}</Accordion.Header>
                        <Accordion.Body>
                          <div className="promotionimg"></div>
                          <p>{t("faq_text5")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="Dial4winSite">
          <Button onClick={handleShow1}>
            <img src={DialWin} alt="dial4win logo" />
          </Button>
        </div>
      </main>

      <Modal
        show={show1}
        className="Dial4winModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose1}
      >
        <div className="borderSection"></div>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Visit this website to play with real money and win Millions of dirhams
          !
        </Modal.Body>
        <Modal.Footer>
          <a
            href="https://dial4win.com"
            target="_blank"
            className="btn btn-primary"
          >
            Confirm
          </a>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LandingPAge;
