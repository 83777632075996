import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Nav, Navbar } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Bonus from "../assets/images/bonus.png";

import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import LogoLightTheme from "../assets/images/LogoLightTheme.png";
import LogoDarkTheme from "../assets/images/LogoDarkTheme.png";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import EnglandFlag from "../assets/images/games-icon/uk.png";
import ProfileIcon1 from "../assets/images/games-icon/profile.png";
import AccountStatement1 from "../assets/images/games-icon/account-statement.png";
import SignOut1 from "../assets/images/games-icon/sign-out.png";
import i18next, { t } from "i18next";
import WithDraw from "../assets/images/games-icon/Withdraw.png";

import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import Evolution1 from "../assets/images/games-icon/Evolution.png";
import CashierIcon1 from "../assets/images/Aviator.png";

import Account from "../assets/images/icons/profilenew.png";
import Transaction from "../assets/images/icons/transaction1.png";
import Referral from "../assets/images/icons/referral.png";
import Promotion from "../assets/images/icons/promotion.png";
import Bonus1 from "../assets/images/icons/bonus1.png";
import SignOut from "../assets/images/icons/logout.png";
import dices from "../assets/images/games-icon/dices.png";
import soccerball from "../assets/images/games-icon/soccer-ball.png";
import casino1 from "../assets/images/games-icon/casino1.png";
import playingcards from "../assets/images/games-icon/playing-cards.png";
import Airplane from "../assets/images/games-icon/airplane.png";

import ProfileIconNew from "../assets/images/icons/profilenew.png";
import ProfileIconNew2 from "../assets/images/icons/menumob.png";
import DepositIcon from "../assets/images/icons/deposit.png";
import Sports from "../assets/images/games-icon/head-icon/fantasy/ball-of-basketball.png";
import Aviator from "../assets/images/games-icon/head-icon/fantasy/plane.png";
import PromotionHead from "../assets/images/games-icon/head-icon/fantasy/marketing.png";
import Spribe from "../assets/images/games-icon/head-icon/fantasy/dice.png";
import LanguageChange from "../views/components/LanguageChange";

import DepositMob from "../assets/images/icons/depositmobile.png";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const isBonus = numberWithCommas(wallet?.coins) || 0;
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [visible, setVisible] = useState(true);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  const [dropShow, setDropShow] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["betby", "spribes", "aviator", "promotion"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <header className="header aftrlgn">
        <div className="container-fluid headerlogin">
          <div className="d-flex justify-content-between align-items-center headerLoginSection">
            <div className="d-flex align-items-center ">
              <div className="logo">
                <a href="/home">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>
              <Navbar expand="xl" className="">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setShowToggle((s) => !s)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <Nav.Link
                      href="/betby"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <img src={Sports} alt="promotion" />
                      {t("sports")}
                    </Nav.Link>
                    <Nav.Link
                      href="/spribes"
                      className={activePage == "spribes" ? "active" : ""}
                    >
                      <img src={Spribe} alt="promotion" />
                      {t("spribe")}
                    </Nav.Link>
                    <Nav.Link
                      href="/casino/spribe/aviator"
                      className={activePage == "aviator" ? "active" : ""}
                    >
                      <img src={Aviator} alt="promotion" />
                      {t("aviator")}
                    </Nav.Link>
                    <Nav.Link
                      href="/promotion"
                      className={activePage == "promotion" ? "active" : ""}
                    >
                      {" "}
                      <img src={PromotionHead} alt="promotion" />
                      {t("promotion")}
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>

            <div className="headerRight">
              {/* <div className=""><LanguageChange /></div> */}
              <div className="language-select">
                <select
                  className="btn-outline-secondary"
                  value={window.localStorage.i18nextLng}
                  onChange={handleLanguageChange}
                >
                  <option value="en">ENG</option>
                  <option value="arab">عربى</option>
                </select>
              </div>
              <div className="funds">
                <Dropdown align="end" className="header-balance">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <span className="d-md-block d-none">{t("balance")} </span>
                    <span>
                      {numberWithCommas(wallet?.cash || 0)}{" "}
                      <span className="d-md-none d-flex">AED</span>
                    </span>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>

              {/* <div className="d-md-none d-block DepositMob">
                <a href="/gatewaylist">
                  <img src={DepositMob} alt="deposit mob" />
                </a>
              </div> */}

              {/* <Button variant="primary menu-btn">
                  <ProfileIcon />
                  <MenuIcon />
                </Button> */}
              <Dropdown className="headerMainDropdown d-none" placement={"end"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {/* <img src={EnglandFlag} /> */}
                  <ProfileIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="">
                    <Button
                      variant="primary  w-100"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      {/* <ProfileIcon /> */}
                      <img src={ProfileIcon1} />
                      <span>{t("profile")}</span>
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <div
                      className="withdraw-style"
                      onClick={() => {
                        navigate("/withdraw");
                      }}
                    >
                      <Button variant="primary  w-100">
                        {" "}
                        <img src={WithDraw} /> {t("withdraw")}
                      </Button>
                    </div>
                  </Dropdown.Item>

                  {/* <Dropdown.Item href="#">
                      <div></div>
                      <Button
                        variant="primary  w-100"
                        onClick={() => dispatch(logoutUser())}
                      >
                        <LogoutIcon />
                        <img src={SignOut1} />
                        <span>Sign Out</span>
                      </Button>
                    </Dropdown.Item> */}
                  <li onClick={() => dispatch(logoutUser())}>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={SignOut1} />
                      {/* <span>Sign Out</span> */}
                      {t("sign_out")}
                    </a>
                  </li>
                </Dropdown.Menu>
              </Dropdown>

              <div
                className="user-drop"
                onClick={() => {
                  setDropShow(!dropShow);
                }}
              >
                <div className="user-drop-wrp">
                  <div className="user-button">
                    <img src={ProfileIconNew} className="d-md-block d-none" />
                    <img src={ProfileIconNew2} className="d-md-none d-block" />
                  </div>
                  <div
                    className={`user-content-section ${dropShow && `d-none`}`}
                  >
                    <div className="user-content">
                      <ul>
                        <li>
                          <span>{user?.mstruserid}</span>
                          <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                        </li>
                        <li>
                          <p className="m-0">
                            {t("balance")}
                            <span>{numberWithCommas(wallet?.cash || 0)}</span>
                          </p>
                        </li>
                        {isBonus != 0 && (
                          <li>
                            <div className="balance-amount">
                              <p>
                                {t("casino_bonus")}
                                <span>
                                  {numberWithCommas(wallet?.coins) || 0}
                                </span>
                              </p>
                            </div>
                          </li>
                        )}
                      </ul>
                      <div className="user-other-option">
                        <ul>
                          <li>
                            <a href="/profile">
                              <img src={Account} />
                              {t("profile")}
                            </a>
                          </li>

                          <li>
                            <a href="/promotion">
                              <img src={Promotion} />
                              {t("promotion")}
                            </a>
                          </li>
                          <li onClick={() => dispatch(logoutUser())}>
                            <a href="/" className="signout">
                              <img src={SignOut} />
                              {t("sign_out")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">{t("main_balance")}</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">{t("coins")}</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">{t("withdraw")}</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/deposit");
                }}
              >
                <Button variant="primary w-100">{t("deposit")}</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>{t("profile")}</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <BetIcon />
                  <span>{t("my_bet")}</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>{t("transaction")}</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <LogoutIcon />
                  <span>{t("sign_out")}</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>

              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <span className="notification-count">2</span>
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <buton
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </buton>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("exposure_amnt")}</th>
                      <th scope="col">{t("match_name")}</th>
                      <th scope="col">{t("match_id")}</th>
                      <th scope="col">{t("total_bet")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("odds")}</th>
                      <th scope="col">{t("stack")}</th>
                      <th scope="col">{t("selection_type")}</th>
                      <th scope="col">{t("status")}</th>
                      <th scope="col">{t("create_date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
