import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { mobileVerification } from "../../../redux/auth/actions";
import { Toaster } from "../../../utils/toaster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_CONST } from "../../../config/const";
import LoginLeftPart from "../../../assets/images/modal1.png";
import { t } from "i18next";
const MobileNumberVerifyModal = ({ show, handleClose, handleModalShow }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtpValue] = useState("");
  const [mobileDetails, setMobileDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getMobileDetails();
  }, []);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };

  const handleSubmit = () => {
    setIsVerifying(true);

    dispatch(mobileVerification({ ...mobileDetails, otp }))
      .then((res) => {
        setIsVerifying(false);
        handleClose();
        handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
        // navigate("/register", {
        //   state: {
        //     referalCode: location?.state?.referalCode,
        //     agentCode: location?.state?.agentCode,
        //   },
        // });
      })
      .catch((err) => {
        setIsVerifying(false);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        {/* <Modal.Title>Sign in now!</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
          <div className="Login-rightPart">
            <Modal.Title>Verify Number</Modal.Title>
            <div className="form-steps">
              <h2 className="h2">Verify!</h2>
              <p>
                Please enter OTP you received on your mobile number and verify.
              </p>
              <Form>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                  <span className="icon">{/* <EyeIcon /> */}</span>
                </Form.Group>
              </Form>

              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isVerifying}
              >
                {!isVerifying ? "VERIFY" : "SUBMITTING"}
              </Button>
              <p className="NoteSection">
                <span>{t("note")}</span>
                {t("note_content")}
              </p>
              <div className="sign-up mt-4">
                Already have an account?
                <br />
                <a href="/sign-in">Sign In</a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MobileNumberVerifyModal;
