import React, { useEffect, useState } from "react";

import DepositIcon1 from "../../assets/images/games-icon/tabs-icon/Deposit.png";
import WithdrawIcon1 from "../../assets/images/games-icon/tabs-icon/Withhdraw.png";
import ProfileIcon1 from "../../assets/images/games-icon/tabs-icon/Profile.png";
import AccountIcon1 from "../../assets/images/games-icon/tabs-icon/Account-Statements.png";
import SignOut12 from "../../assets/images/games-icon/tabs-icon/Sign-Out.png";
import BonusIcon1 from "../../assets/images/games-icon/tabs-icon/Bonus.png";
import Refferal from "../../assets/images/games-icon/tabs-icon/Refferals.png";
import SportsBet from "../../assets/images/games-icon/tabs-icon/CasinoBet.png";
import CasinoBet from "../../assets/images/games-icon/tabs-icon/CasinoBet.png";
import Promotion from "../../assets/images/games-icon/tabs-icon/Promotion.png";
import ContactUs from "../../assets/images/games-icon/tabs-icon/Contact.png";

import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";
import { useLocation } from "react-router-dom";

import { t } from "i18next";

const LeftSideComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activePage, setActivePage] = useState("");

  const sideItems = [
    "profile",
    "deposit",
    "withdraw",
    "bonus",
    "referral",
    "cashier",
    "casinobet",
    "promotion",
    "contactus",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = sideItems.find((f) => location?.pathname.includes(f));
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  return (
    <div class="PageLeftBar">
      <div className="LeftBarSide">
        <ul>
          <li>
            <a
              href="/profile"
              className={activePage === "profile" ? "sideBaractive" : ""}
            >
              <img src={ProfileIcon1} />
              <span>{t("profile")}</span>
            </a>
          </li>

          <li>
            <a
              href="/casinobet"
              className={activePage === "casinobet" ? "sideBaractive" : ""}
            >
              <img src={CasinoBet} />
              <span>{t("bet_history")}</span>
            </a>
          </li>

          <li onClick={() => dispatch(logoutUser())}>
            <a href="/">
              <img src={SignOut12} />
              <span>{t("sign_out")}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LeftSideComponents;
