import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import { t } from "i18next";

function RulesRegulation() {
  return (
    <>
      <Header />

      <main className="main pagecontent">
        <div className="container">
          <h2 class="sectionTitle">{t("rules_regiulations")}</h2>
          <div className="terms-content sub-points">
            <p>{t("rules_regiulations_p_one")}</p>
            <p>
              <span>{t("rules_regiulations_span_one")}</span>
              {t("rules_regiulations_p_two")}
            </p>
            <p>
              <span>{t("rules_regiulations_span_two")}</span>
              {t("rules_regiulations_p_three")}
            </p>
            <p>
              <span>{t("rules_regiulations_span_three")}</span>
              {t("rules_regiulations_p_four")}
              <br />
              {t("rules_regiulations_p_five1")}
              {/* <br />
              {t("rules_regiulations_p_five2")}
              <br />
              {t("rules_regiulations_p_five3")} */}
            </p>
            <p>
              <span>{t("rules_regiulations_span_four")}</span>
              {t("rules_regiulations_p_six")}
            </p>
            <p>
              <span>{t("rules_regiulations_span_five")}</span>
              {t("rules_regiulations_p_seven")}
            </p>
            <p>
              <span>{t("rules_regiulations_span_six")}</span>
              {t("rules_regiulations_p_eight")}
            </p>
            <p>
              <span>{t("rules_regiulations_span_seven")}</span>
              {t("rules_regiulations_p_nine")}
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default RulesRegulation;
