import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";

import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Twitter from "../assets/images/icons/twitter.png";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import Accordion from "react-bootstrap/Accordion";
import CasinoAfterIcon from "../assets/images/chip.png";
import DepositIcon1 from "../assets/images/savings.png";
import Exchange from "../assets/images/Exchange.png";
import HomeIcon1 from "../assets/images/dial4win.png";
import Support from "../assets/images/games-icon/support.png";

import FAQImg from "../assets/images/faq/faq-img.svg";
import faqAnimation from "../assets/images/faq/faq-animation.svg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Sports from "../assets/images/games-icon/head-icon/fantasy/ball-of-basketball.png";
import Aviator from "../assets/images/games-icon/head-icon/fantasy/plane.png";
import Promotion from "../assets/images/games-icon/head-icon/fantasy/marketing.png";
import Spribe from "../assets/images/games-icon/head-icon/fantasy/dice.png";

import { t } from "i18next";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <footer className="footer main">
        <div className="container">
          <div className="footer-links">
            <div className="FooterPart">
              <h3>{t("about")}</h3>
              <ul>
                <li>
                  <a href="/aboutus">{t("about_us")}</a>
                </li>
                <li>
                  <a href="/responsiblegames">{t("responsible_gaming")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("help")}</h3>
              <ul>
                <li>
                  <a href="/contactuss">{t("contact_us")}</a>
                </li>
                <li>
                  <a href="/termss">{t("general_terms_condition")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("regulations")}</h3>
              <ul>
                <li>
                  <a href="/privacypolicys">{t("privacy_policy")}</a>
                </li>
                <li>
                  <a href="/fairplays">{t("fair_play")}</a>
                </li>
                <li>
                  <a href="/rules&regulation">{t("rules_regiulations")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("social")}</h3>
              <ul>
                {/* <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <span className="notification-count">2</span>
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  <span>WhatsApp</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                  <span>Telegram</span>
                </a>
              </li>
              */}
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    <span>{t("facebook")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                    <span>{t("instagram")}</span>
                  </a>
                </li>
                <li>
                  <a className="panel" href="https://twitter.com/Dial4W133">
                    <img src={Twitter} alt="Instagram Icon" />
                    <span>{t("twitter")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-none d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/betby");
                  }}
                >
                  <img src={Sports} alt="promotion" />
                  <span>{t("sports")}</span>
                </a>
              </li>

              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/spribe");
                  }}
                >
                  <img src={Spribe} alt="promotion" />
                  <span>{t("spribe")}</span>
                </a>
              </li>
              <li className="active-foot">
                <a className="active" href="/landing-page">
                  <img src={HomeIcon1} className="homeImg" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Aviator} alt="promotion" />
                  <span>{t("aviator")}</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/promotions");
                  }}
                >
                  {/* <CashierIcon /> */}
                  <img src={Promotion} alt="promotion" />
                  <span>{t("promotion")}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <p className="copyright">{appDetails?.FOOTER_TEXT || ""}</p>
    </>
  );
};

export default BeforeLoginFooter;
