import React from "react";
import { useSelector } from "react-redux";

import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

import PlinkoGame from "../../../../assets/images/games/Plinko.png";
import GoalGame from "../../../../assets/images/games/gOAL.png";
import DiceGame from "../../../../assets/images/games/dICE.png";
import HiloGame from "../../../../assets/images/games/Hilospribe.png";
import MineGame from "../../../../assets/images/games/Minespribe.png";
import MiniRouletteGame from "../../../../assets/images/games/mINIROULETTE.png";
import AviatorGame from "../../../../assets/images/games/Aviator.png";

import VivoGaming from "../../../../assets/images/games/vivo.jpg";
import OnedayTeenPatti from "../../../../assets/images/games/OnedayTeenpatti.jpg";
import CashoorCrash from "../../../../assets/images/games/cashorcrash.jpg";
import UltimateRoulette from "../../../../assets/images/games/UltimateRoulette.jpg";
import VivoAndarbahr from "../../../../assets/images/games/ANDARBAHAR.jpg";
import VivoTeenPatti from "../../../../assets/images/games/Teenpatti.jpg";
import FootballStudio from "../../../../assets/images/games/Footballstudio.jpg";

import i18next, { t } from "i18next";

const Spribe = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main">
        <div className="container">
          <div className="SpribeSection">
            <h2>{t("spribe")}</h2>
            <div className="GameSection">
              <div className="games">
                <a className="aviator game">
                  <img src={PlinkoGame} alt="aviator" />
                </a>
                <a className="mine game">
                  <img src={MineGame} alt="mine" />
                </a>
                <a className="mini-roulette game">
                  <img src={MiniRouletteGame} alt="mini roulette" />
                </a>
                <a className="goal game">
                  <img src={GoalGame} alt="goal" />
                </a>
                <a className="hilo game">
                  <img src={HiloGame} alt="hilo" />
                </a>
                <a className="dice game">
                  <img src={DiceGame} alt="dice" />
                </a>
              </div>
              <div className="gameRightPart">
                <a className="aviator game">
                  <img src={AviatorGame} alt="aviator" />
                </a>
              </div>
            </div>
          </div>

          <div className="SpribeSection d-none">
            <h2>{t("popular_games")}</h2>
            <div className="GameSection">
              <div className="games">
                <a className="aviator game" href="/casino/vivo-andar-bahar">
                  <img src={VivoAndarbahr} alt="aviator" />
                </a>
                <a className="aviator game" href="/casino/vivo-teen-patti">
                  <img src={VivoTeenPatti} alt="aviator" />
                </a>
                <a
                  className="aviator game"
                  href="/casino/ezg-ultimate-roulette"
                >
                  <img src={UltimateRoulette} alt="aviator" />
                </a>
                <a className="mine game" href="/casino/ezgevo-cash-or-crash">
                  <img src={CashoorCrash} alt="mine" />
                </a>
                <a className="mine game" href="/casino/ezgevo-football-studio">
                  <img src={FootballStudio} alt="mine" />
                </a>
                <a className="mine game" href="/casino/ezg-one-day-teen-patti">
                  <img src={OnedayTeenPatti} alt="mine" />
                </a>
              </div>
              <div className="gameRightPart">
                <a className="aviator game" href="/casino/vivo">
                  <img src={VivoGaming} alt="aviator" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default Spribe;
