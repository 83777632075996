import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";

import DepositIcon1 from "../../../../assets/images/games-icon/Deposit.png";
import WithdrawIcon1 from "../../../../assets/images/games-icon/Withdraw1.png";
import ProfileIcon1 from "../../../../assets/images/games-icon/profile1.png";
import AccountIcon1 from "../../../../assets/images/games-icon/account-statement1.png";
import SignOut12 from "../../../../assets/images/games-icon/sign-out1.png";
import { logoutUser } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";

const KYC = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="transfer_info_page">
          <Container>
            <div className="PageSection">
              <div class="PageLeftBar">
                <div className="LeftBarSide">
                  <ul>
                    <li>
                      <a href="/deposit">
                        <img src={DepositIcon1} />
                        <span>Deposit</span>
                      </a>
                    </li>
                    <li>
                      <a href="/withdraw">
                        <img src={WithdrawIcon1} />
                        <span>Withdraw</span>
                      </a>
                    </li>
                    <li>
                      <a href="/profile">
                        <img src={ProfileIcon1} />
                        <span>Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="/cashier">
                        <img src={AccountIcon1} />
                        <span>Transaction</span>
                      </a>
                    </li>
                    <li>
                      <a href="/kyc">
                        <img src={AccountIcon1} />
                        <span>KYC</span>
                      </a>
                    </li>
                    <li>
                      <a href="/promotion">
                        <img src={AccountIcon1} />
                        <span>Promotion</span>
                      </a>
                    </li>
                    <li onClick={() => dispatch(logoutUser())}>
                      <a href="/">
                        <img src={SignOut12} />
                        <span>Sign Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="PageRightBar">
                <div className="RightBarHead">
                  <h2>KYC</h2>
                </div>
                <div className="kyc-form tabs-area ">
                  <Form>
                    <Form.Group
                      className="form-group mb-3"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>Amount*</Form.Label> */}
                      <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>
                    <Form.Group
                      className="form-group mb-2"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>UTR No*</Form.Label> */}
                      <Form.Control type="number" placeholder="Mobile No" />
                    </Form.Group>
                    <Form.Group
                      className="form-group mb-2"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>UTR No*</Form.Label> */}
                      <Form.Control type="text" placeholder="Email" />
                    </Form.Group>
                    <Form.Group
                      className="form-group mb-2"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>File*</Form.Label> */}
                      <Form.Control type="file" />
                    </Form.Group>
                    <Form.Group
                      className="form-group mb-2"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>File*</Form.Label> */}
                      <Form.Control type="file" />
                    </Form.Group>
                    <Col xs={12} className="text-center mt-2">
                      <butto className="btn btn-primary py-2 px-5">
                        Submit
                      </butto>
                    </Col>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
    </div>
  );
};

export default KYC;
