import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import { t } from "i18next";

function BeforePrivacyPolicy() {
  return (
    <>
      <Header />

      <main className="main pagecontent">
        <div className="container">
          <h2 className="sectionTitle">{t("privacy_policy")}</h2>
          <div className="privay-content sub-points">
            <p>{t("privacy_policy_p_one")}</p>
            <p>
              <span>{t("privacy_policy_span_one")}</span>
              {t("privacy_policy_p_two")}
            </p>
            <p>
              <span>{t("privacy_policy_span_two")}</span>
              {t("privacy_policy_p_three")}
            </p>
            <p>
              <span>{t("privacy_policy_span_three")}</span>
              {t("privacy_policy_p_four")}
            </p>
            <p>
              <span>{t("privacy_policy_span_four")}</span>
              {t("privacy_policy_p_five")}
            </p>
            <p>
              <span>{t("privacy_policy_span_five")}</span>
              {t("privacy_policy_p_six")}
            </p>
            <p>
              <span>{t("privacy_policy_span_six")}</span>
              {t("privacy_policy_p_seven")}
            </p>
            <p>
              <span>{t("privacy_policy_span_seven")}</span>
              {t("privacy_policy_p_eight")}
              <br />
              {t("privacy_policy_p_nine")}
            </p>
            <p>
              <span>{t("privacy_policy_span_eight")}</span>
              {t("privacy_policy_p_ten")}
              <br />
              {t("privacy_policy_p_eleven")}
            </p>
            <p>
              <span>{t("privacy_policy_span_nine")}</span>
              {t("privacy_policy_p_twelve")}
            </p>
            <p>
              <span>{t("privacy_policy_span_ten")}</span>
              {t("privacy_policy_p_thirteen")}
            </p>
            <p>
              <span>{t("privacy_policy_span_eleven")}</span>
              {t("privacy_policy_p_fourteen")}
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default BeforePrivacyPolicy;
