import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import Header from "../../../../containers/Header";
import FirstPromoImg from "../../../../assets/images/promotion/firstprmotion.png";
import SecondPromoImg from "../../../../assets/images/promotion/secondpromotion.png";
import ThirdPromoImg from "../../../../assets/images/promotion/thirdpromotion.png";
import SignuPromoImg from "../../../../assets/images/promotion/signuppromotion.png";
import ReferralPromoImg from "../../../../assets/images/promotion/referralpromotion.png";
import WeekendPromoImg from "../../../../assets/images/promotion/weekendpromotion.png";

import i18next, { t } from "i18next";

const Promotion = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <Header />
      <main className="main">
        <div className="container">
          <div className="PageSection beforepromotion">
            <div className="PageRightBar">
              <div className="RightBarHead d-flex justify-content-between">
                <h2>{t("promotion")}</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  {t("back")} <BackIcon />
                </div>
              </div>

              <div className="promotion-section kyc-form tabs-area d-none">
                <div className="promo-form">
                  <h3>{t("get_promo_code")}</h3>
                  <Form>
                    <Form.Group
                      className="form-group mx-3"
                      controlId="formGroupAmount"
                    >
                      {/* <Form.Label>Amount*</Form.Label> */}
                      <Form.Control type="text" placeholder="Code" />
                    </Form.Group>
                    <button className="btn btn-primary py-2 px-5">Check</button>
                  </Form>
                </div>
              </div>

              <div className="promo-section">
                <ul>
                  <li>
                    <a href="/signuppromos" className="promo-content">
                      <img src={SignuPromoImg} />
                      <div className="content">
                        <h4>{t("sign_up_bonus")}</h4>
                        <p>{t("sign_up_bonus_p_one")}</p>
                        <a href="/signuppromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/firstpromos" className="promo-content">
                      <img src={FirstPromoImg} />
                      <div className="content">
                        <h4>{t("first_deposit_bonus")}</h4>
                        <p>{t("first_deposit_bonus_p_one")}</p>
                        <a href="/firstpromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/secondpromos" className="promo-content">
                      <img src={SecondPromoImg} />
                      <div className="content">
                        <h4>{t("second_deposit_bonus")}</h4>
                        <p>{t("second_deposit_bonus_p_one")}</p>
                        <a href="/secondpromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/thirdpromos" className="promo-content">
                      <img src={ThirdPromoImg} />
                      <div className="content">
                        <h4>{t("third_deposit_bonus")}</h4>
                        <p>{t("third_deposit_bonus_p_one")}</p>
                        <a href="/thirdpromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/referralpromos" className="promo-content">
                      <img src={ReferralPromoImg} />
                      <div className="content">
                        <h4>{t("referral_bonus")}</h4>
                        <p>{t("referral_bonus_p_one")}</p>
                        <a href="/referralpromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/weekendpromos" className="promo-content">
                      <img src={WeekendPromoImg} />
                      <div className="content">
                        <h4>{t("weekend_exclusive_bonus")}</h4>
                        <p>{t("loss_deposit_bonus_homepage")}</p>
                        <a href="/weekendpromos">{t("see_more")}</a>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Promotion;
