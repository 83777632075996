import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import CalendarIcon from "../../../../assets/svg/CalendarIcon";
import { getAccountStatement } from "../../../../redux/user/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import LeftSideComponents from "../../../components/LeftSideComponents";

import { t } from "i18next";

const CasinoBet = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(Date()).format("YYYY-MM-DD"));
  const [isSearched, setIsSearched] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const { accountStatement } = useSelector((state) => state.user);

  useEffect(() => {
    if (fromDate && toDate) {
      getData(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const handleSearch = (value) => {
    if (value) {
      setIsSearched(true);
      let filteredValue = accountStatement.filter((item) => {
        return item.comments.indexOf(value) > -1;
      });
      setFilteredData(filteredValue);
    } else {
      setIsSearched(false);
    }
  };
  const getData = (from, to) => {
    const payload = {
      from,
      to,
    };
    dispatch(getAccountStatement(payload));
  };

  const renderBets = (item) => {
    return (
      <li className="casino-bets-sectiom">
        <div className="">
          <div className="casinoBetsDetails">
            <h4 className="h4">
              {item?.txn_type === "Dr" ? "Debit" : "Credit"}
            </h4>
            <span className="details">{item?.comments}</span>
            <small>{moment(item?.created_at).format("DD-MM-YY h:mm")}</small>
            <h5 className="h5">
              {item?.txn_type === "Dr" ? "-" : "+"} {item?.amount}
            </h5>
            <span
              style={
                item?.txn_type === "Dr"
                  ? {
                      color: "#FF0000",
                    }
                  : {
                      color: "#279500",
                    }
              }
              className="statue"
            >
              {item?.txn_type === "Dr" ? "Debit" : "Credit"}
            </span>
            <small className="balance">
              {t("balance_text")}: {item?.after_balance}
            </small>
          </div>
        </div>
      </li>
    );
  };
  return (
    <div className="">
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="container ">
          <div className="PageSection">
            <LeftSideComponents />
            <div className="PageRightBar">
              <div className="RightBarHead d-flex justify-content-between">
                <h2>{t("casino_bet")}</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  {t("back")} <BackIcon />
                </div>
              </div>
              <div className="account-statement AccountSection mt-4">
                <div className="row">
                  <div className="col-6">
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Text input with dropdown button"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        type="date"
                      />
                      <Button variant="primary calender-icon">
                        <CalendarIcon />
                      </Button>
                    </InputGroup>
                  </div>
                  <div className="col-6">
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Text input with dropdown button"
                        value={toDate}
                        type="date"
                        onChange={(e) => setToDate(e.target.value)}
                      />
                      <Button variant="primary calender-icon">
                        <CalendarIcon />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <InputGroup className="mb-3 search-icon">
                      <Form.Control
                        placeholder={t("search")}
                        aria-label="Text input with dropdown button"
                        type="search"
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <ul>
                  <span>
                    <li>{t("dr_cr")}</li>
                    <li>{t("comments")}</li>
                    <li>{t("created_at")}</li>
                    <li>{t("amount")}</li>
                    <li>{t("status")}</li>
                    <li>{t("balance_text")}</li>
                  </span>

                  {!isSearched
                    ? accountStatement?.length &&
                      accountStatement
                        ?.filter(
                          (item) =>
                            item.referance_type === 3 ||
                            item.referance_type === 4
                        )
                        .map((item, index) => {
                          return renderBets(item);
                        })
                    : filteredData?.length &&
                      filteredData
                        ?.filter(
                          (item) =>
                            item.referance_type === 3 ||
                            item.referance_type === 4
                        )
                        .map((item, index) => {
                          return renderBets(item);
                        })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CasinoBet;
