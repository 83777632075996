import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CasinoAfterIcon from "../assets/images/chip.png";
import DepositIcon1 from "../assets/images/savings.png";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Twitter from "../assets/images/icons/twitter.png";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import HomeIcon1 from "../assets/images/dial4win.png";

import Support from "../assets/images/games-icon/support.png";

import FAQImg from "../assets/images/faq/faq-img.svg";
import faqAnimation from "../assets/images/faq/faq-animation.svg";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import rng from "../assets/images/providers/RNG.png";
import plus from "../assets/images/providers/18Plus.png";
import GamblingCOmmission from "../assets/images/providers/GamblingCOmmission.png";
import RGF from "../assets/images/providers/RGF.png";

import ProviderLogo4 from "../assets/images/providers/Spribe.jpg";
import ProviderLogo5 from "../assets/images/providers/netent.jpg";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sports from "../assets/images/games-icon/head-icon/fantasy/ball-of-basketball.png";
import Aviator from "../assets/images/games-icon/head-icon/fantasy/plane.png";
import Promotion from "../assets/images/games-icon/head-icon/fantasy/marketing.png";
import Spribe from "../assets/images/games-icon/head-icon/fantasy/dice.png";

import { t } from "i18next";
import { Accordion, Button, Modal } from "react-bootstrap";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const footerPages = [
    "home",
    "/betby",
    "Casioafter",
    "casino/ezugi",
    "deposit",
    "contactus",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <footer className="footer main aftrlgn">
        <div className="container">
          <div className="footer-links">
            <div className="FooterPart">
              <h3>{t("about")}</h3>
              <ul>
                <li>
                  <a href="/AboutUss">{t("about_us")}</a>
                </li>
                <li>
                  <a href="/responsiblegame">{t("responsible_gaming")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("help")}</h3>
              <ul>
                <li>
                  <a href="/contactus">{t("contact_us")}</a>
                </li>
                <li>
                  <a href="/terms">{t("general_terms_condition")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("regulations")}</h3>
              <ul>
                <li>
                  <a href="/privacypolicy">{t("privacy_policy")}</a>
                </li>
                <li>
                  <a href="/fairplay">{t("fair_play")}</a>
                </li>
                <li>
                  <a href="/rules">{t("rules_regiulations")}</a>
                </li>
              </ul>
            </div>
            <div className="FooterPart">
              <h3>{t("social")}</h3>
              <ul>
                {/* <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <span className="notification-count">2</span>
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  <span>WhatsApp</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                  <span>Telegram</span>
                </a>
              </li>
             */}
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    <span>{t("facebook")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                    <span>{t("instagram")}</span>
                  </a>
                </li>

                <li>
                  <a className="panel" href="https://twitter.com/Dial4W133">
                    <img src={Twitter} alt="Instagram Icon" />
                    <span>{t("twitter")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  className={activePage === "betby" ? "active" : ""}
                  href="/betby"
                >
                  <img src={Sports} alt="promotion" />
                  <span>{t("sports")}</span>
                </a>
              </li>
              {/* <li>
                <a
                  className={activePage === "spribes" ? "active" : ""}
                  href="/spribes"
                >
                  <img src={Spribe} alt="promotion" />
                  <span>{t("spribe")}</span>
                </a>
              </li> */}
              <li className="active-foot">
                <Button onClick={handleShow1}>
                  <img src={HomeIcon1} className="homeImg" />
                </Button>
              </li>
              {/* <Button onClick={handleShow1}>
                <img src={DialWin} alt="dial4win logo" />
              </Button> */}
              <li>
                <a
                  className={activePage === "aviator" ? "active" : ""}
                  href="/casino/spribe/aviator"
                >
                  <img src={Aviator} alt="promotion" />
                  <span>{t("aviator")}</span>
                </a>
              </li>
              {/* <li>
                <a
                  className={activePage === "promotion" ? "active" : ""}
                  href="/promotion"
                >
                  {/* <CashierIcon /> 
                  <img src={Promotion} alt="promotion" />
                  <span>{t("promotion")}</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </footer>
      <p className="copyright">{appDetails?.FOOTER_TEXT || ""}</p>

      <Modal
        show={show1}
        className="Dial4winModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose1}
      >
        <div className="borderSection"></div>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Visit this website to play with real money and win Millions of dirhams
          !
        </Modal.Body>
        <Modal.Footer>
          <a
            href="https://dial4win.com"
            target="_blank"
            className="btn btn-primary"
          >
            Confirm
          </a>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Footer;
