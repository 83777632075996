import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import gmail from "../../../../assets/images/gmail.png";
import phonecall from "../../../../assets/images/phone-call.png";
import livechat from "../../../../assets/images/live-chat.png";

import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Twitter from "../../../../assets/images/twitter.png";
import { useDispatch, useSelector } from "react-redux";

import socialmedia from "../../../../assets/images/social-media.png";
import contactform from "../../../../assets/images/form.png";
import Facebook from "../../../../assets/images/facebook.png";

import { t } from "i18next";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  console.log("appDetails", appDetails);

  return (
    <>
      <HeaderAfterLogin />

      <main className="main pagecontent">
        <div className="container">
          <h2 className="sectionTitle">{t("contact_us")}</h2>
          <div className="payment-info">
            <div class="social-icons contact-social d-none">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {/* <span className="notification-count">2</span> */}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
                <li>
                  <a className="panel" href="https://twitter.com/Dial4W133">
                    <img src={Twitter} alt="Twitter Icon" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="other-contact">
              <p className="foloowingpoints">{t("contact_us_p_one")}</p>
              <p className="mt-5">
                <img src={livechat} className="contact_icons" />
                {t("contact_us_p_two")}
              </p>
              <p className="mt-5">
                <img src={gmail} className="contact_icons" />
                {t("contact_us_p_three1")}
                <a href="mailto:dial4win@gmail.com">dial4win@gmail.com</a>
                {t("contact_us_p_three2")}
              </p>
              <p className="mt-5 social">
                <img src={socialmedia} className="contact_icons" />
                {t("contact_us_p_four")}
                <a
                  className="panel"
                  href={
                    appDetails?.FACEBOOK != ""
                      ? appDetails?.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={Facebook} alt="Telegram Icon" className="" />
                </a>
                <a
                  className=""
                  href={
                    appDetails?.INSTATGRAM != ""
                      ? appDetails?.INSTATGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" className="" />
                </a>
                <a href="https://twitter.com/Dial4W133">
                  <img src={Twitter} className="" />
                </a>
                {t("contact_us_p_five")}
              </p>
              <p className="mt-5">
                <img src={contactform} className="contact_icons" />
                {t("contact_us_p_six")}
              </p>
              <p className="mt-5 px-0">{t("contact_us_p_seven")}</p>
              <p className="mt-5 px-0">
                {t("contact_us_p_eight1")}
                <span className="ltr">24*7</span>. {t("contact_us_p_eight2")}
              </p>
              <p className="mt-5 px-0">{t("contact_us_p_nine")}</p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ContactUs;
