import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import { t } from "i18next";

function AboutUss() {
  return (
    <>
      <HeaderAfterLogin />

      <main className="main pagecontent">
        <div className="container">
          <h2 class="sectionTitle">{t("about_us")}</h2>
          <div className="terms-content">
            <p>{t("about_us_p_one")}</p>
            <p>{t("about_us_p_two")}</p>
            <p>{t("about_us_p_three")}</p>
            <p>{t("about_us_p_four")}</p>
            <p>{t("about_us_p_five")}</p>
            {/* <p>{t("about_us_p_six")}</p>
            <p>{t("about_us_p_seven")}</p>
            <p>{t("about_us_p_eight")}</p> */}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default AboutUss;
