import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import { t } from "i18next";

function ResponsibleGame() {
  return (
    <>
      <HeaderAfterLogin />

      <main className="main pagecontent">
        <div className="container">
          <h2 className="sectionTitle">{t("responsible_gaming")}</h2>
          <div className="responsible-game sub-points">
            <p>{t("responsible_gaming_p_one")}</p>
            <p>
              <span>{t("responsible_gaming_span_one")}</span>
              {t("responsible_gaming_p_two1")}
              {/* <br />
              {t("responsible_gaming_p_two2")}
              <br />
              {t("responsible_gaming_p_two2")} */}
            </p>
            <p>
              <span>{t("responsible_gaming_span_two")}</span>
              {t("responsible_gaming_p_three1")}
              {/* <br />
              {t("responsible_gaming_p_three2")} */}
            </p>
            <p>
              <span>{t("responsible_gaming_span_three")}</span>
              {t("responsible_gaming_p_four1")}
              {/* <br />
              {t("responsible_gaming_p_four2")} */}
            </p>
            <p>
              <span>{t("responsible_gaming_span_four")}</span>
              {t("responsible_gaming_p_five1")}
              {/* <br />
              {t("responsible_gaming_p_five2")} */}
            </p>
            <p>
              <span>{t("responsible_gaming_span_five")}</span>
              {t("responsible_gaming_p_six")}
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default ResponsibleGame;
