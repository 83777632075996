import { APP_DETAILS, BANK_DETAILS, BANNERS } from "./types";
import { axiosService } from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";
import { AxiosError } from "axios";
import { Toaster } from "../../utils/toaster";
import { APP_CONST } from "../../config/const";

export const getAppDetails = () => (dispatch) => {
  const result = axiosService.get(API_URLS.APP.APP_DETAILS);
  result.then((res) => {
    if (res?.data?.error === false) {
      dispatch({
        type: APP_DETAILS,
        payload: res?.data?.data,
      });
    }
  });
};

export const getActiveBankDetails = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.BANK_DETAILS);
    result
      .then((res) => {
        dispatch({
          type: BANK_DETAILS,
          payload: res.data.data.bankDetails,
        });
      })
      .catch((err) => {
        console.debug(err);
      });
  } catch (err) {
    console.debug(err);
  }
};

export const getBanners = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.GET_BANNERS);
    result.then((res) => {
      dispatch({
        type: BANNERS,
        payload: res.data?.data?.banners,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

export const submitUserDetails = async (payload) => {
  try {
    return await axiosService.post(API_URLS.APP.PUBLIC_USER_INFO, payload);
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Failed to submit details");
    }
  }
};
