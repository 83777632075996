import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositIcon1 from "../../../../assets/images/games-icon/Deposit.png";
import WithdrawIcon1 from "../../../../assets/images/games-icon/Withdraw1.png";
import ProfileIcon1 from "../../../../assets/images/games-icon/profile1.png";
import AccountIcon1 from "../../../../assets/images/games-icon/account-statement1.png";
import SignOut12 from "../../../../assets/images/games-icon/sign-out1.png";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Table } from "react-bootstrap";
import { IoEye } from "react-icons/io5";
import { useEffect } from "react";
import { BonusApiHelper } from "./bonusCard";
import { useState } from "react";
import LeftSideComponents from "../../../components/LeftSideComponents";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
import { API_URLS } from "../../../../config/apiUrls";
import moment from "moment/moment";

const Bonus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useSWRFetcher({
    url: API_URLS.BONUS.CLAIMS,
    isExternalFetch: true,
  });
  return (
    <>
      <HeaderAfterLogin />
      <main className="main mt-4">
        <div className="d-xl-none mt--14">{/* <LeftBarSports /> */}</div>
        <div className="container">
          <div className="PageSection">
            <LeftSideComponents />
            <div className="PageRightBar">
              <div className="RightBarHead  d-flex justify-content-between">
                <h2>Bonus</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  Back <BackIcon />
                </div>
              </div>
              <div className="ProfileMainSec">
                <div className="tabs-area">
                  <div className="table-responsive bonus_table">
                    <Table className="referr_table">
                      <thead>
                        <tr>
                          {/* <th width="">Name</th> */}
                          <th width="">Code</th>
                          <th>Bonus Type</th>
                          <th width="">Bonus Amount</th>
                          <th width="">Rollover Amount </th>
                          {/* <th>Parts</th> */}
                          <th width="">Created date</th>
                          <th width="">View </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data?.statement) &&
                          data?.statement.map((item, index) => (
                            <tr key={index}>
                              {/* <td>{item.bonusName}</td> */}
                              <td>{item.bonusCode}</td>
                              <td>{item.bonusType}</td>
                              <td>
                                {item.creditedAmount} / {item.creditableAmount}
                              </td>
                              <td>
                                {item.claimedAmount} / {item.goalAmount}
                              </td>
                              {/* <td>
                              {item.claimedParts} / {item.totalParts}
                            </td> */}
                              <td>
                                {" "}
                                {moment(item.createdAt).format("DD-MM-YY h:mm")}
                              </td>

                              <td>
                                {" "}
                                <Link to={`/bonus/${item.id}`}>
                                  <IoEye className="eye_" />
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Bonus;
