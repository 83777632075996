import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import "react-phone-input-2/lib/style.css";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EnglandFlag from "../assets/images/games-icon/uk.png";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";

import i18next, { t } from "i18next";

import Sports from "../assets/images/games-icon/head-icon/fantasy/ball-of-basketball.png";
import Aviator from "../assets/images/games-icon/head-icon/fantasy/plane.png";
import Promotion from "../assets/images/games-icon/head-icon/fantasy/marketing.png";
import Spribe from "../assets/images/games-icon/head-icon/fantasy/dice.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";

import LanguageChange from "../views/components/LanguageChange";

const Header = () => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [showToggle, setShowToggle] = useState(true);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  const { appDetails } = useSelector((state) => state.app);

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  return (
    <>
      {visible ? (
        <div className="marqu">
          <marquee>
            <img src={StarIcon} alt="star icon" />
            {t("marquee")}
            <img src={StarIcon} alt="star icon" />
          </marquee>
          <span onClick={() => setVisible(!visible)}>
            <AiOutlineClose />
          </span>
        </div>
      ) : (
        ""
      )}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="logo">
                      <a href="/">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                      </a>
                    </div>
                    <Navbar expand="xl" className="">
                      <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        onClick={() => setShowToggle((s) => !s)}
                      />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                          <Nav.Link href="/betby" className="">
                            <img src={Sports} alt="promotion" />
                            {t("sports")}
                          </Nav.Link>
                          <Nav.Link
                            href="/spribe
                          "
                            className=""
                          >
                            <img src={Spribe} alt="promotion" />
                            {t("spribe")}
                          </Nav.Link>
                          <Nav.Link href="/casino/spribe/aviator">
                            <img src={Aviator} alt="promotion" />
                            {t("aviator")}
                          </Nav.Link>
                          <Nav.Link href="/promotions">
                            <img src={Promotion} alt="promotion" />
                            {t("promotion")}
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>

                  <div className="headerRight">
                    {/* <div className=""><LanguageChange /></div> */}
                    <div className="language-select">
                      <select
                        className="btn-outline-secondary"
                        value={window.localStorage.i18nextLng}
                        onChange={handleLanguageChange}
                      >
                        <option value="en">ENG</option>
                        <option value="arab">عربى</option>
                      </select>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      {t("login")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/user-email");
                      }}
                      className=" btn_signup ms-3"
                    >
                      {t("signup")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
        <div className="bottom_head d-none">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon1} alt="bottom head link icon" />
                      <span>Inplay</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon2} alt="bottom head link icon" />
                      <span>Cricket</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon3} alt="bottom head link icon" />
                      <span>Football</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>TeenPatti</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>Roulette</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Forget Username / Password
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
