import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import { t } from "i18next";

function BeforeFairPlay() {
  return (
    <>
      <Header />

      <main className="main pagecontent">
        <div className="container">
          <h2 class="sectionTitle">{t("fair_play")}</h2>
          <div className="fairplay sub-points">
            <p>{t("fair_play_p_one")}</p>
            <p>
              <span>{t("fair_play_span_one")}</span>
              {t("fair_play_p_two1")}
              {/* <br />
              {t("fair_play_p_two2")} */}
            </p>

            <p>
              <span>{t("fair_play_span_two")}</span>
              {t("fair_play_p_three1")}
              {/* <br />
              {t("fair_play_p_three2")} */}
            </p>
            <p>
              <span>{t("fair_play_span_three")}</span>
              {t("fair_play_p_four1")}
              {/* <br />
              {t("fair_play_p_four2")} */}
            </p>
            <p>
              <span>{t("fair_play_span_four")}</span>
              {t("fair_play_p_five")}
            </p>
            <p>
              <span>{t("fair_play_span_five")}</span>
              {t("fair_play_p_six1")}
              <br />
              {t("fair_play_p_six2")}
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default BeforeFairPlay;
